import React, { useEffect, useState } from "react";
import AdvanceFilterHeader from "./AdvanceFilterHeader";
import AdvanceFilterMain from "./AdvanceFilterMain";
import AdvanceFilterFooter from "./AdvanceFilterFooter";
import ProgramSearchService from "../../../Services/ProgramSearchService";
import { toast } from "react-toastify";

export default function AdvanceFilter({
  setApplySelectedFilters,
  findProgram,
  appliedDurationAndFee,
  setAppliedDurationAndFee,
  checkedOptions,
  setCheckedOptions,
  selectedOptions,
  setSelectedOptions,
  setCurrentPage,
  formatSessionIntakeDate,
}) {
  const [advanceDataList, setAdvanceDataList] = useState([]);
  const [originalTabList, setOriginalTabList] = useState([]);
  const getAdvanceSearchFilterData = async () => {
    try {
      const response = await ProgramSearchService.advanceFilterList();
      const data = response.data;

      setAdvanceDataList(data);

      const uniqueIntakeDates = data.intakeLst.reduce((acc, intake) => {
        const date = new Date(intake.date);
        const year = date.getFullYear();
        const month = date.getMonth();

        const existing = acc.find((item) => {
          const existingDate = new Date(item);
          return (
            existingDate.getFullYear() === year &&
            existingDate.getMonth() === month
          );
        });

        if (!existing) {
          acc.push(intake.date); // unqiue dates
        }
        return acc;
      }, []);

      const seasionIntakes = data?.intakeLst?.map((item) => {
        return item;
      });

      setOriginalTabList([
        {
          tabTitle: "College Name",
          navTo: "collegeName",
          optionsList: data.collegeNameLst,
        },
        {
          tabTitle: "Campus",
          navTo: "campus",
          optionsList: data.campusLst,
        },
        {
          tabTitle: "Program Type",
          navTo: "programType",
          optionsList: data.programTypeLst,
        },
        {
          tabTitle: "Province",
          navTo: "province",
          optionsList: data.provinceLst,
        },
        {
          tabTitle: "Intake",
          navTo: "intake",
          optionsList: seasionIntakes,
        },
        {
          tabTitle: "English Requirement",
          navTo: "englishRequirement",
          optionsList: [
            "IELTS",
            "TOEFL",
            "PTE",
            "Duolingo",
            "GMAT",
            "GRE",
            "SAT",
          ],
        },
        {
          tabTitle: "Areas of Interest",
          navTo: "areasofInterest",
          optionsList: data.areasofInterestLst,
        },
        {
          tabTitle: "Current Residence",
          navTo: "currentResidence",
          optionsList: [
            "India",
            "Canada",
            "Australia",
            "USA",
            "UK",
            "New Zealand",
            "Ireland",
          ],
        },
        {
          tabTitle: "Nationality",
          navTo: "nationality",
          optionsList: [
            "India",
            "Canada",
            "Australia",
            "USA",
            "UK",
            "New Zealand",
            "Ireland",
          ],
        },
      ]);
    } catch (e) {
      toast.error(e);
    }
  };

  useEffect(() => {
    getAdvanceSearchFilterData();
  }, []);

  const [filteredTabList, setFilteredTabList] = useState([]);

  useEffect(() => {
    setFilteredTabList(originalTabList); // Initialize filteredTabList with originalTabList
  }, [originalTabList]);

  const [searchInput, setSearchInput] = useState("");
  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };
  useEffect(() => {
    const lowerCaseSearchInput = searchInput.toLowerCase(); // Convert searchInput to lowercase

    const filteredList = originalTabList.map((filterTab) => {
      const matchingOptions = filterTab?.optionsList?.filter((option) =>
        option?.toLowerCase().includes(lowerCaseSearchInput)
      );

      // Create a copy of the original object with the filtered optionsList
      return {
        ...filterTab,
        optionsList: matchingOptions,
      };
    });

    setFilteredTabList(filteredList);
  }, [searchInput]);

  const [durationAndFee, setDurationAndFee] = useState({
    durationNumber: 0,
    durationPeriod: "",
    applicationFee: -1,
    tuitionFee: -1,
  });
  const handleDurationAndFee = (e) => {
    const { name, value } = e.target;
    setDurationAndFee((prevData) => ({ ...prevData, [name]: value }));
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const handleActive = (index) => {
    setActiveIndex(index);
  };
  const handleCheckboxClick = (tabTitle, option) => {
    // Check if the option is already checked
    const isChecked = checkedOptions[tabTitle]?.includes(option);

    if (isChecked) {
      // If the option is already checked, remove it from both selectedOptions and checkedOptions
      setSelectedOptions((prevSelectedOptions) => {
        const updatedOptions = { ...prevSelectedOptions };
        if (updatedOptions[tabTitle]) {
          updatedOptions[tabTitle] = updatedOptions[tabTitle].filter(
            (item) => item !== option
          );
        }
        return updatedOptions;
      });

      setCheckedOptions((prevCheckedOptions) => {
        const updatedOptions = { ...prevCheckedOptions };
        if (updatedOptions[tabTitle]) {
          updatedOptions[tabTitle] = updatedOptions[tabTitle].filter(
            (item) => item !== option
          );
        }
        return updatedOptions;
      });
    } else {
      // If the option is not checked, add it to both selectedOptions and checkedOptions
      setSelectedOptions((prevSelectedOptions) => {
        const updatedOptions = { ...prevSelectedOptions };
        if (updatedOptions[tabTitle]) {
          updatedOptions[tabTitle].push(option);
        } else {
          updatedOptions[tabTitle] = [option];
        }
        return updatedOptions;
      });

      setCheckedOptions((prevCheckedOptions) => {
        const updatedOptions = { ...prevCheckedOptions };
        if (updatedOptions[tabTitle]) {
          updatedOptions[tabTitle].push(option);
        } else {
          updatedOptions[tabTitle] = [option];
        }
        return updatedOptions;
      });
    }
  };

  const handleClearAllFilters = () => {
    setCheckedOptions([]);
    setSelectedOptions({});
    setApplySelectedFilters("");
    setFilteredTabList(originalTabList);
    setAppliedDurationAndFee({
      durationNumber: 0,
      durationPeriod: "",
      applicationFee: -1,
      tuitionFee: -1,
    });
  };
  console.log({ selectedOptions });

  const handleApplyFilters = () => {
    setCurrentPage(1);
    setApplySelectedFilters(selectedOptions);
    setAppliedDurationAndFee(durationAndFee);
  };

  return (
    <div>
      <div
        className="modal fade"
        id="filterModal"
        tabIndex="-1"
        aria-labelledby="filterModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
          <div className="modal-content rounded-5 pb-4 pt-2 bg-light-blue1">
            <div className="mt-3 mx-4 pb-2 border-bottom d-flex justify-content-between align-items-center">
              <div className="text1-6 font-bold">Filter:</div>
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control rounded-pill border py-2 mx-2 text1-2"
                  placeholder="Search option"
                  style={{ width: "280px" }}
                  required
                  value={searchInput}
                  onChange={handleSearchInputChange}
                />

                <div className="top-50 end-0 position-absolute top-50 end-0 translate-middle-y me-3">
                  <button
                    className="btn btn-dark-blue rounded-circle"
                    style={{ width: "24px", height: "24px" }}
                  >
                    <span className="">
                      <i className="bi bi-search d-flex justify-content-center align-items-center text1-3"></i>
                    </span>
                  </button>
                </div>
              </div>
              <div
                className="text-dark1 text-decoration-none text1-3"
                style={{ cursor: "pointer" }}
                onClick={() => setSearchInput("")}
              >
                Clear Search
              </div>
            </div>
            <div className="modal-body bg-white m-0 p-0">
              <AdvanceFilterMain
                filterTabList={filteredTabList}
                activeIndex={activeIndex}
                handleActive={handleActive}
                handleCheckboxClick={handleCheckboxClick}
                checkedOptions={checkedOptions}
                advanceDataList={advanceDataList}
                handleDurationAndFee={handleDurationAndFee}
                durationAndFee={durationAndFee}
                setDurationAndFee={setDurationAndFee}
                appliedDurationAndFee={appliedDurationAndFee}
                formatSessionIntakeDate={formatSessionIntakeDate}
              />
            </div>
            <div className="modal-footer">
              <AdvanceFilterFooter
                handleApplyFilters={handleApplyFilters}
                handleClearAllFilters={handleClearAllFilters}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
