import React, { useCallback, useEffect, useState } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import WalletDataService from "../../Services/WalletService";

export default function CheckoutForm({
  exchangeData,
  newWalletData,
  setNewWalletData,
  handleClose,
  findWalletData,
  retrieveDashbaord,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [isStripeLoading, setIsStripeLoading] = useState(true);

  useEffect(() => {
    if (elements) {
      const element = elements.getElement("payment");
      element.on("ready", () => {
        setIsStripeLoading(false);
      });
    }
  }, [elements]);

  useEffect(() => {
    if (!stripe) {
      return;
    }
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    if (!clientSecret) {
      return;
    }
    stripe
      .retrievePaymentIntent(clientSecret)
      .then(async ({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case "succeeded":
            setMessage("Payment succeeded!");
            break;
          case "processing":
            setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            setMessage("Your payment was not successful, please try again.");
            break;
          default:
            setMessage("Something went wrong.");
            break;
        }
      });
  }, [stripe]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!stripe || !elements) {
        return;
      }
      setIsLoading(true);
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {},
        redirect: "if_required",
      });
      const paydata = paymentIntent ? paymentIntent : error.payment_intent;
      if (error) {
        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(error.message);
          toast.error(error.message);
        } else {
          setMessage(
            error.message ? error.message : "An unexpected error occurred."
          );
          toast.error(
            error.message ? error.message : "An unexpected error occurred."
          );
        }
        setIsLoading(false);
      }
      const data = {
        amount: newWalletData.amount,
        companyId: newWalletData.companyId,
        type: "Credit",
        remarks: newWalletData.remarks,
        transactionId: paydata.id,
        responseText: paydata.status,
        statusId: 1,

        currencyConversionRate: exchangeData.exchangeRate,
        currencyConversionAmount: exchangeData.convertedAmount,
      };
      if (paydata.status === "succeeded") {
        try {
          const response = await WalletDataService.create(data);
          if (response.data.success === true) {
            handleClose();
            await findWalletData();
            await retrieveDashbaord();
            setNewWalletData({
              amount: 0,
              remarks: "",
              companyId: newWalletData.companyId,
              type: "Credit",
            });
            toast.success(response.data.message);
          } else if (
            response.data.success === false &&
            response.data.validationErrors === null
          ) {
            toast.info("Try again");
          } else if (response.data.validationErrors) {
            response.data.validationErrors.map((verr) => toast.error(verr));
          } else {
            toast.error("Something Went Wrong saving in db");
          }
        } catch (e) {
          toast.error(e);
        } finally {
          setIsLoading(false);
        }
      }

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.

      setIsLoading(false);
    },
    [
      elements,
      exchangeData.convertedAmount,
      exchangeData.exchangeRate,
      findWalletData,
      handleClose,
      newWalletData.amount,
      newWalletData.companyId,
      newWalletData.remarks,
      retrieveDashbaord,
      setNewWalletData,
      stripe,
    ]
  );

  return (
    <form
      onSubmit={handleSubmit}
      className={`w-100 px-4 py-3 border rounded-4 shadow`}
      style={{ minWidth: "420px" }}
    >
      <LinkAuthenticationElement />
      <PaymentElement
        options={{
          layout: "tabs",
        }}
      />
      {isStripeLoading ? (
        <></>
      ) : (
        <>
          <button
            disabled={isLoading || !stripe || !elements}
            id="submit"
            className={`${
              isLoading ? "btn-light-green" : "btn-light-green-outline"
            } btn rounded-pill py-3 font-bold text1-6 w-100 d-flex justify-content-center align-items-center mt-3`}
          >
            <span id="button-text">
              {isLoading || !stripe || !elements ? (
                <span className="spinner-border"></span>
              ) : (
                <span className="font-bold text1-6">
                  Add ${exchangeData.baseAmount} CAD
                </span>
              )}
            </span>
          </button>
          {/* Show any error or success messages */}
          <div className="text-center text1-4 mt-3 d-none">
            &#x20B9;{exchangeData.convertedAmount} (1 CAD = &#x20B9;
            {exchangeData.exchangeRate})
          </div>
        </>
      )}

      {message && <div className="text-center text1-4 mt-3">{message}</div>}
    </form>
  );
}
