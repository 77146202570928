import React, { useCallback, useMemo } from "react";

export default function EditImage({
  fileInputRef,
  setBannerData,
  bannerData,
  updated,
  setUpdated,
  setImageError,
}) {
  const handleCollegeLogoInput = useCallback(
    async (event) => {
      const { name, type, files, value } = event.target;
      const file = files[0];
      if (file) {
        setImageError("");
        const reader = new FileReader();
        reader.onloadend = () => {
          setBannerData((p) => ({
            ...p,
            bannerImageContent: reader.result.split(",")[1],
            bannerName: file.name,
            type: file.type,
          }));
        };
        reader.readAsDataURL(file);
      }
    },
    [setBannerData]
  );

  const editImageContent = useMemo(() => {
    switch (true) {
      case !!bannerData?.bannerImageUrl:
        return (
          <div className=" d-flex justify-content-center mt-2 position-relative">
            <img
              src={bannerData?.bannerImageUrl}
              alt="updated"
              className=""
              style={{ maxWidth: "100%", maxHeight: "180px" }}
            />
            <button
              className="btn btn-link btn-cross"
              style={{
                position: "absolute",
                right: "19px",
                top: "-13px",
              }}
              onClick={() => {
                setUpdated(true);
                setBannerData((prevInfo) => ({
                  ...prevInfo,
                  bannerImageUrl: "",
                }));
              }}
            >
              <i className="bi bi-x-circle-fill text-danger text2"></i>
            </button>
          </div>
        );
      case !!bannerData?.bannerImageContent:
        console.log({ bannerData });
        return (
          <div className=" d-flex justify-content-center mt-2 position-relative">
            <img
              src={`data:${bannerData?.type};base64,${bannerData?.bannerImageContent}`}
              alt="Uploaded"
              className=""
              style={{ maxWidth: "100%", maxHeight: "180px" }}
            />
            <button
              className="btn btn-link btn-cross"
              style={{
                position: "absolute",
                right: "19px",
                top: "-13px",
              }}
              onClick={() => {
                setUpdated(true);
                setBannerData((prevInfo) => ({
                  ...prevInfo,
                  bannerImageContent: "",
                  type: "",
                  bannerName: "",
                }));
              }}
            >
              <i className="bi bi-x-circle-fill text-danger text2"></i>
            </button>
          </div>
        );
      default:
        return (
          <>
            <div className="text1-4 text-dark1 d-flex align-items-center justify-content-center mt-1">
              <label
                htmlFor={`importInput_${bannerData.name}`}
                style={{ cursor: "pointer" }}
              >
                <div className="d-flex gap-1 align-items-center">
                  <i className="bi bi-cloud-plus text-dark1 text2-5 d-flex align-items-center justify-content-center me-2"></i>

                  <span>Upload Image</span>
                </div>
              </label>

              <input
                id={`importInput_${bannerData.name}`}
                type="file"
                className="d-none"
                ref={fileInputRef}
                accept="image/jpeg,image/jpg,image/png"
                onChange={handleCollegeLogoInput}
              />
            </div>
          </>
        );
    }
  }, [
    bannerData,
    fileInputRef,
    handleCollegeLogoInput,
    setBannerData,
    setUpdated,
  ]);

  return editImageContent;
}
