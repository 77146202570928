import { useCallback, useEffect, useState } from "react";

import { toast } from "react-hot-toast";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgramsDataService from "../../Services/ProgramSearchService";
import ProgramSearchService from "../../Services/ProgramSearchService";

export default function AddPrograms({ pageTitle, collegeData, getData }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const initializeData = {
    collegeId: "",
    name: "",
    author: "",
    campus: [],
    duration: "",
    credential: "",
    tuitionFee: 0,
    academicRequirement: "",
    //applicationProcessingTime: "",
    pgWorkPermit: "",
    websiteUrl: "",
    statusId: 1,
    ieltsRequirement: "",
    pteRequirement: "",
    toeflRequirement: "",
    duolingoRequirement: "",
    gmat: "",
    gre: "",
    sat: "",
    areasofInterest: "",
    specificRequirement1: "",
    specificRequirement2: "",
  };

  const [ProgramsData, setProgramsData] = useState(initializeData);

  const [validated, setValidated] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProgramsData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [campuses, setCampuses] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  const getCollegeCampuses = useCallback(async () => {
    if (!ProgramsData.collegeId) return;
    const response = await ProgramSearchService.getCollegeCampus(
      ProgramsData.collegeId
    );
    setCampuses(response.data);
  }, [ProgramsData.collegeId]);

  useEffect(() => {
    getCollegeCampuses();
  }, [getCollegeCampuses]);

  useEffect(() => {
    setProgramsData((p) => ({ ...p, campus: [] }));
  }, [ProgramsData.collegeId]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    setValidated(true);

    if (form.checkValidity() === false) {
      event.stopPropagation();
      return; // Exit the function if the form is invalid
    }

    var newData = {
      collegeId: ProgramsData.collegeId,
      name: ProgramsData.name,
      author: "",
      campusList: ProgramsData.campus,
      duration: ProgramsData.duration,
      credential: ProgramsData.credential,
      tuitionFee: ProgramsData.tuitionFee,
      academicRequirement: ProgramsData.academicRequirement,
      //applicationProcessingTime: ProgramsData.applicationProcessingTime,
      pgWorkPermit: ProgramsData.pgWorkPermit,
      courseUrl: ProgramsData.courseUrl,
      statusId: ProgramsData.statusId,
      ieltsRequirement: ProgramsData.ieltsRequirement,
      pteRequirement: ProgramsData.pteRequirement,
      toeflRequirement: ProgramsData.toeflRequirement,
      duolingoRequirement: ProgramsData.duolingoRequirement,
      gmat: ProgramsData.gmat,
      gre: ProgramsData.gre,
      sat: ProgramsData.sat,
      areasofInterest: ProgramsData.areasofInterest,
      specificRequirement1: ProgramsData.specificRequirement1,
      specificRequirement2: ProgramsData.specificRequirement2,
    };

    try {
      // Display loading message
      const loadingMessage = toast.loading("Creating...");

      const response = await ProgramsDataService.create(newData);

      if (response.data.success === true) {
        getData();
        toast.success(response.data.message, { id: loadingMessage });
        setProgramsData(initializeData);
        setValidated(false);
        setShow(false);
      } else if (
        response.data.success === false &&
        response.data.validationErrors.length > 0
      ) {
        response.data.validationErrors.map((verr) =>
          toast.error(verr, { id: loadingMessage })
        );
      } else {
        toast.error("Something Went Wrong", { id: loadingMessage });
      }
    } catch (error) {
      toast.error("An error occurred");
    }
  };

  const handleMultipleSelectCampus = useCallback(
    (campusName) => {
      const findCampusId = ProgramsData?.campus?.find(
        (camp) => camp === campusName
      );
      if (!findCampusId) {
        setProgramsData((p) => {
          return { ...p, campus: [...p.campus, campusName] };
        });
      } else {
        const newCampuses = ProgramsData?.campus?.filter(
          (camp) => camp !== campusName
        );
        setProgramsData((p) => ({ ...p, campus: newCampuses }));
      }
    },
    [ProgramsData?.campus]
  );

  return (
    <>
      <Button
        variant="primary"
        onClick={handleShow}
        className="py-2 px-4 fs-5 rounded-pill"
      >
        Add New {pageTitle}
      </Button>

      <Modal show={show} onHide={handleClose} className="mt-5" size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add {pageTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            className="px-3"
          >
            <Row xs={2} md={3} className="g-3">
              <Col md={12}>
                <FloatingLabel
                  controlId="floatingSelect"
                  label="Select College"
                >
                  <Form.Select
                    aria-label="select college"
                    defaultValue={ProgramsData.collegeId}
                    name="collegeId"
                    onChange={handleInputChange}
                  >
                    <option>Select College</option>
                    {collegeData.map((cd) => (
                      <option value={cd.id} key={cd.id}>
                        {cd.collegeName}
                      </option>
                    ))}
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col md={12}>
                <FloatingLabel controlId="floatingInput" label="name">
                  <Form.Control
                    type="text"
                    placeholder="name"
                    required
                    name="name"
                    value={ProgramsData.name}
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide name
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <FloatingLabel
                controlId="floatingInput"
                label="campus"
                onMouseLeave={() => setShowDropdown(false)}
              >
                <Form.Control
                  type="text"
                  placeholder="Campus"
                  value={ProgramsData?.campus?.join(", ")}
                  onClick={() => setShowDropdown((p) => !p)}
                  style={{ cursor: "pointer" }}
                />
                {showDropdown ? (
                  <ul
                    class="list-group bg-light ps-0 z-3 position-absolute shadow bg-white rounded border-dark"
                    style={{ width: "96%" }}
                  >
                    {campuses?.map((campus) => (
                      <li
                        class={`list-group-item campus-item text1-3 ${
                          ProgramsData?.campus?.includes(campus.campus)
                            ? "bg-light"
                            : ""
                        }`}
                        onClick={() =>
                          handleMultipleSelectCampus(campus.campus)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {ProgramsData?.campus?.includes(campus.campus) ? (
                          <i class="bi bi-check-circle-fill me-2"></i>
                        ) : (
                          <i class="bi bi-circle me-2"></i>
                        )}
                        {campus.campus}
                      </li>
                    ))}
                  </ul>
                ) : null}

                <Form.Control.Feedback type="invalid">
                  Please provide campus
                </Form.Control.Feedback>
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="duration">
                <Form.Control
                  type="text"
                  placeholder="duration"
                  required
                  name="duration"
                  value={ProgramsData.duration}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide duration
                </Form.Control.Feedback>
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="credential">
                <Form.Control
                  type="text"
                  placeholder="credential"
                  required
                  name="credential"
                  value={ProgramsData.credential}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide credential
                </Form.Control.Feedback>
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="tuitionFee">
                <Form.Control
                  type="number"
                  placeholder="tuitionFee"
                  required
                  name="tuitionFee"
                  value={ProgramsData.tuitionFee}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide tuitionFee
                </Form.Control.Feedback>
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingInput"
                label="academicRequirement"
              >
                <Form.Control
                  type="text"
                  placeholder="academicRequirement"
                  required
                  name="academicRequirement"
                  value={ProgramsData.academicRequirement}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide academicRequirement
                </Form.Control.Feedback>
              </FloatingLabel>
              {/* <FloatingLabel controlId="floatingInput" label="applicationProcessingTime">
                <Form.Control
                  type="text"
                  placeholder="applicationProcessingTime"
                  required
                  name="applicationProcessingTime"
                  value={ProgramsData.applicationProcessingTime}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">Please provide applicationProcessingTime</Form.Control.Feedback>
              </FloatingLabel> */}
              <FloatingLabel controlId="floatingInput" label="pgWorkPermit">
                <Form.Select
                  aria-label="select pgWorkPermit"
                  value={ProgramsData.pgWorkPermit}
                  name="statusId"
                  onChange={handleInputChange}
                >
                  <option value={"Yes"}>Yes</option>
                  <option value={"No"}>No</option>
                  <option value={"N/A"}>N/A</option>
                </Form.Select>

                <Form.Control.Feedback type="invalid">
                  Please provide pgWorkPermit
                </Form.Control.Feedback>
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="ieltsRequirement">
                <Form.Control
                  type="text"
                  placeholder="ieltsRequirement"
                  name="ieltsRequirement"
                  value={ProgramsData.ieltsRequirement}
                  onChange={handleInputChange}
                />
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="pteRequirement">
                <Form.Control
                  type="text"
                  placeholder="pteRequirement"
                  name="pteRequirement"
                  value={ProgramsData.pteRequirement}
                  onChange={handleInputChange}
                />
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="toeflRequirement">
                <Form.Control
                  type="text"
                  placeholder="toeflRequirement"
                  name="toeflRequirement"
                  value={ProgramsData.toeflRequirement}
                  onChange={handleInputChange}
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingInput"
                label="duolingoRequirement"
              >
                <Form.Control
                  type="text"
                  placeholder="duolingoRequirement"
                  name="duolingoRequirement"
                  value={ProgramsData.duolingoRequirement}
                  onChange={handleInputChange}
                />
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="gmat">
                <Form.Control
                  type="text"
                  placeholder="gmat"
                  name="gmat"
                  value={ProgramsData.gmat}
                  onChange={handleInputChange}
                />
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="gre">
                <Form.Control
                  type="text"
                  placeholder="gre"
                  name="gre"
                  value={ProgramsData.gre}
                  onChange={handleInputChange}
                />
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="sat">
                <Form.Control
                  type="text"
                  placeholder="sat"
                  name="sat"
                  value={ProgramsData.sat}
                  onChange={handleInputChange}
                />
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="areasofInterest">
                <Form.Control
                  type="text"
                  placeholder="areasofInterest"
                  required
                  name="areasofInterest"
                  value={ProgramsData.areasofInterest}
                  onChange={handleInputChange}
                />
              </FloatingLabel>
              <Col md={12}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Specific Requirement"
                >
                  <Form.Control
                    as="textarea"
                    className="text1-3"
                    placeholder="Specific Requirement"
                    name="specificRequirement1"
                    value={ProgramsData.specificRequirement1}
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide Program Specific Requirement
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={12}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Other Specific Requirement"
                >
                  <Form.Control
                    as="textarea"
                    className="text1-3"
                    placeholder="Other Specific Requirement"
                    name="specificRequirement2"
                    value={ProgramsData.specificRequirement2}
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide Program Other Specific Requirement
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col md={12}>
                <FloatingLabel controlId="floatingInput" label="courseUrl">
                  <Form.Control
                    as="textarea"
                    className="text1-3"
                    placeholder="courseUrl"
                    name="courseUrl"
                    value={ProgramsData.courseUrl}
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide courseUrl
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <FloatingLabel controlId="floatingSelect" label="Select Status">
                <Form.Select
                  aria-label="select Status"
                  value={ProgramsData.statusId}
                  name="statusId"
                  onChange={handleInputChange}
                >
                  <option value={0}>Inactive</option>
                  <option value={1}>Active</option>
                </Form.Select>
              </FloatingLabel>
              <Col md={12}>
                <Modal.Footer className="mt-4">
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button type="submit" variant="success">
                    save
                  </Button>
                </Modal.Footer>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
