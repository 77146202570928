import React, { useCallback, useEffect, useRef, useState } from "react";
import UserPanel from "../UserPanel";
import BarChart from "../../components/BarChart";
import LineChart from "../../components/LineChart";
import applicationState from "../../assets/icons/applicationState.svg";
import studentsStat from "../../assets/icons/studentsStat.svg";
import offersStat from "../../assets/icons/offersStat.svg";
import tuitionStat from "../../assets/icons/tuitionStat.svg";
import leadsStat from "../../assets/icons/leadsStat.svg";
import wallet1 from "../../assets/icons/wallet1.svg";
import visaIcon from "../../assets/institution/icon2.svg";
import DashboardService from "../../Services/DashboardService";
import { toast } from "react-toastify";
import TokenService from "../../Services/TokenService";
import RoleService from "../../Services/RolesService";
import PageTitle from "../../components/PageTitle";
import WalletPay from "../Wallet/WalletPay";
import WalletDataService from "../../Services/WalletService";
import { Link } from "react-router-dom";
import { AddMoneyToWalletModal } from "../../shared/components/AddMoneyToWalletModal";
import { Bar } from "react-chartjs-2";
import Slider from "react-slick";
import { Card } from "react-bootstrap";
import CompleteProfile from "./CompleteProfile";

const pageTitle = "Dashboard";

const inilitizeDasboardData = {
  totalStudents: 0,
  totalApplications: 0,
  totalOffers: 0,
  totalLeads: 0,
  totalFeesDeposited: 0,
  totalWalletAmount: 0,
};
const options = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      backgroundColor: "#fff",
      titleColor: "#000",
      bodyColor: "#000",
    },
  },
  maintainAspectRatio: false,
  scales: {
    y: {
      border: { dash: [6, 6] },
      grid: {
        color: "#DCDCDC",
      },
      ticks: {
        color: "#5A5A72",
        precision: 0,
        beginAtZero: true,
        font: {
          size: 16,
        },
      },
      beginAtZero: true,
    },
    x: {
      ticks: {
        color: "#5A5A72",
        font: {
          size: 16,
        },
      },
      grid: {
        lineWidth: 0,
      },
    },
  },
};
const initializeData = {
  id: "",
  collegeName: "",
  description: "",
  bannerName: "",
  bannerImageUrl: "",
  statusId: 0,
  createdBy: "",
  created: "",
};

export default function Dashboard() {
  const getRole = TokenService.getUserRole();
  const userDetails = TokenService.getUser();
  const { SuperAdmin, BranchHead, Accounts } = RoleService;
  const [agentProfileCompleteModal, setAgentProfileCompleteModal] =
    useState(false);
  const [announcementData, setAnnouncementData] = useState([initializeData]);

  const [dashboardDetails, setDashboardDetails] = useState(
    inilitizeDasboardData
  );
  const runOnce = useRef(false);

  const [showAddMoneyModal, setShowAddMoneyModal] = useState(false);
  const [filter, setFilter] = useState("Monthly");

  const retrieveDashbaord = async () => {
    try {
      let response;
      if (getRole === SuperAdmin) {
        response = await DashboardService.getAll();
      }
      if (getRole === BranchHead) {
        response = await DashboardService.dashboardByCompanyId();
      }
      if (response.data.success === true) {
        setDashboardDetails(response.data.response);
      }
    } catch (error) {
      toast.error("Error while fetching dashboard data");
    }
  };
  const floatValue = (value) => {
    return value.toFixed(2);
  };
  const [visaStatus, setVisaStatus] = useState([]);

  const getVisaStatus = useCallback(async () => {
    try {
      const response = await DashboardService.getApplicationCount({
        filter: filter,
      });

      setVisaStatus({
        labels: Object.keys(response.data.response.data),
        datasets: [
          {
            label: "No. of Applications",
            data: Object.values(response.data.response.data),
            backgroundColor: "#FC7753",
            maxBarThickness: 20,
            borderRadius: 4,
          },
        ],
      });
    } catch (error) {
      console.log(error);
      toast.error("Error while fetching student applications data.");
    }
  }, [filter]);

  const [walletAmounts, setWalletAmounts] = useState([0]);
  const findWalletData = () => {
    const params = { currentPage: 1, pageSize: 1000 * 1000 };
    if (getRole === SuperAdmin || getRole === Accounts) {
      WalletDataService.getAllWalletList(params).then((response) => {
        setWalletAmounts(response.data.map((item) => item.amount));
      });
    } else {
      WalletDataService.find(params).then((response) => {
        setWalletAmounts(response.data.response.map((item) => item.amount));
      });
    }
  };

  const getAnnouncements = useCallback(async () => {
    try {
      const response = await DashboardService.getAnnouncements();

      if (response.data.success === true) {
        setAnnouncementData(response.data.response);
      }
    } catch (error) {
      toast.error("Error while fetching announcements data");
    }
  }, []);

  const handleSelect = useCallback((e) => {
    setFilter(e.target.value);
  }, []);

  useEffect(() => {
    retrieveDashbaord();
    findWalletData();
  }, []);
  useEffect(() => {
    getVisaStatus();
  }, [getVisaStatus]);
  useEffect(() => {
    getAnnouncements();
  }, [getAnnouncements]);

  useEffect(() => {
    const hasShownPopup = sessionStorage.getItem("hasShownPopup");
    if (
      !hasShownPopup &&
      !userDetails.isUserProfileCompleted &&
      getRole === "Branch Head"
    ) {
      setAgentProfileCompleteModal(true);
      sessionStorage.setItem("hasShownPopup", "true");
    }
  }, [getRole, userDetails.isUserProfileCompleted]);

  const carouselSettings = {
    dots: true,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    autoplay: true,
    pauseOnFocus: true,
    pauseOnHover: true,
    speed: 500,
    autoplaySpeed: 2800,
    adaptiveHeight: true,
    arrows: false,
  };

  return (
    <>
      <CompleteProfile
        showModal={agentProfileCompleteModal}
        handleCloseModal={() => setAgentProfileCompleteModal(false)}
      />
      <PageTitle title={pageTitle} />
      <div className="">
        <UserPanel title={pageTitle} />
        <div className="row">
          <div className="col-12">
            <div className="row mb-3">
              <div className="col">
                <Link
                  to="/user/students"
                  className="bg-light-blue p-4 rounded-5 d-flex justify-content-between align-items-center text-black text-decoration-none"
                >
                  <div>
                    <button
                      className="btn bg-normal-blue d-flex justify-content-center align-items-center rounded-circle"
                      style={{ width: "52px", height: "52px" }}
                    >
                      {/* <i className="bi bi-people text-white fs-1 d-flex align-items-center"></i> */}
                      <img
                        src={studentsStat}
                        className="img-fluid"
                        alt="students_stat"
                      />
                    </button>
                  </div>
                  <div className="text2-2 text-end me-3">
                    <span className="font-bold">
                      {dashboardDetails.totalStudents}
                    </span>
                    <p className="text1-3">Students</p>
                  </div>
                </Link>
              </div>
              <div className="col">
                <Link
                  to="/user/applications"
                  className="bg-light-green p-4 rounded-5 d-flex justify-content-between align-items-center text-black text-decoration-none"
                >
                  <div>
                    <button
                      className="btn bg-normal-green d-flex justify-content-center align-items-center rounded-circle"
                      style={{ width: "52px", height: "52px" }}
                    >
                      {/* */}
                      <img
                        src={applicationState}
                        className="img-fluid"
                        alt="applications_stat"
                      />
                    </button>
                  </div>
                  <div className="text2-2 text-end me-3">
                    <span className="font-bold">
                      {dashboardDetails.totalApplications}
                    </span>
                    <p className="text1-3">Applications</p>
                  </div>
                </Link>
              </div>
              <div className="col">
                <Link
                  to="/user/applications"
                  state={{ newLabelName: "Offer Received" }}
                  className="bg-light-yellow p-4 rounded-5 d-flex justify-content-between align-items-center text-black text-decoration-none"
                >
                  <div>
                    <button
                      className="btn bg-normal-yellow d-flex justify-content-center align-items-center rounded-circle"
                      style={{ width: "52px", height: "52px" }}
                    >
                      {/* <i className="bi bi-percent text-white fs-1 d-flex align-items-center"></i> */}
                      <img
                        src={offersStat}
                        className="img-fluid"
                        alt="offers_stat"
                      />
                    </button>
                  </div>
                  <div className="text2-2 text-end me-3">
                    <span className="font-bold">
                      {dashboardDetails.totalOffers}
                    </span>
                    <p className="text1-3">Offer Received</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="row mb-4">
              <div className="col">
                <Link
                  to="/user/lead"
                  className="bg-light-blue p-4 rounded-5 d-flex justify-content-between align-items-center text-black text-decoration-none"
                >
                  <div>
                    <button
                      className="btn bg-normal-red d-flex justify-content-center align-items-center rounded-circle"
                      style={{ width: "52px", height: "52px" }}
                    >
                      {/* <i className="bi bi-bar-chart-line text-white fs-1 d-flex align-items-center"></i> */}
                      <img
                        src={leadsStat}
                        className="img-fluid"
                        alt="leads_stat"
                      />
                    </button>
                  </div>
                  <div className="text2-2 text-end me-3">
                    <span className="font-bold">
                      {dashboardDetails.totalLeads}
                    </span>
                    <p className="text1-3">Lead</p>
                  </div>
                </Link>
              </div>
              <div className="col">
                <Link
                  to="/user/applications"
                  state={{ newLabelName: "Tuition Fee Paid" }}
                  className="bg-light-green p-4 rounded-5 d-flex justify-content-between align-items-center text-black text-decoration-none"
                >
                  <div>
                    <button
                      className="btn bg-normal-orange d-flex justify-content-center align-items-center rounded-circle"
                      style={{ width: "52px", height: "52px" }}
                    >
                      {/* <i className="bi bi-cash text-white fs-1 d-flex align-items-center"></i> */}
                      <img
                        src={tuitionStat}
                        className="img-fluid"
                        alt="tuition_stat"
                      />
                    </button>
                  </div>
                  <div className="text2-2 text-end me-3">
                    <span className="font-bold">
                      {dashboardDetails.noOfApplicationsWithFeesDeposited}
                      {/* ${floatValue(dashboardDetails.totalFeesDeposited)} */}
                    </span>
                    <p className="text1-3">Tuition Fee Paid</p>
                  </div>
                </Link>
              </div>
              <div className="col">
                <Link
                  to="/user/applications"
                  state={{ newLabelName: "Visa Approved" }}
                  className="bg-light-yellow p-4 rounded-5 d-flex justify-content-between align-items-center text-black text-decoration-none"
                >
                  <div>
                    <button
                      className="btn bg-light-gray2 d-flex justify-content-center align-items-center rounded-circle"
                      style={{ width: "52px", height: "52px" }}
                    >
                      <img
                        src={visaIcon}
                        className="img-fluid"
                        alt="wallet-1"
                      />
                    </button>
                  </div>
                  <div className="text2-2 text-end me-3">
                    <span className="font-bold">
                      {dashboardDetails.totalVisaApproved}
                    </span>
                    <p className="text1-3">Visa Approved</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="row mb-4">
              <div className="col">
                <Link
                  to="/user/wallet"
                  className="bg-light-blue p-4 rounded-5 d-flex justify-content-between align-items-center text-black text-decoration-none"
                >
                  <div className="d-flex gap-5">
                    <button
                      className="btn bg-dark1 d-flex justify-content-center align-items-center rounded-circle"
                      style={{ width: "52px", height: "52px" }}
                    >
                      {/* <i className="bi bi-wallet2  text-white fs-1 d-flex align-items-center"></i> */}
                      <img src={wallet1} className="img-fluid" alt="wallet-1" />
                    </button>
                    <div className="text2-2 me-3">
                      <span className="font-bold">
                        ${floatValue(dashboardDetails.totalWalletAmount)}
                      </span>
                      <p className="text1-3">Wallet</p>
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center rounded-pill btn-light-blue-outline p-3 h6 text-decoration-none text1-6"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowAddMoneyModal(true);
                    }}
                  >
                    <div
                      className="bg-dark1 d-flex justify-content-center align-items-center rounded-circle me-2 text3"
                      style={{ width: "32px", height: "32px" }}
                    >
                      <i className="bi bi-plus text-white d-flex justify-content-center align-items-center"></i>
                    </div>
                    Add Money
                  </div>
                </Link>
              </div>
            </div>
          </div>

          {showAddMoneyModal ? (
            <AddMoneyToWalletModal
              show={true}
              handleClose={() => {
                setShowAddMoneyModal(false);
              }}
              findWalletData={findWalletData}
              retrieveDashbaord={retrieveDashbaord}
            />
          ) : null}

          <div className="col-12">
            <div className="row mb-3">
              <div className="col-md-6 col-sm-12 mb-sm-3">
                <div className="bg-light-blue p-4 rounded-4">
                  <div className="d-flex gap-3 justify-content-between mb-3">
                    <div className="text2 font-bold">
                      Applications Submitted
                    </div>
                    <div className="w-25">
                      <select
                        className="form-select rounded-4 text1-4 py-3 "
                        defaultValue={"Montly"}
                        onChange={handleSelect}
                        id="floatingSelect"
                      >
                        <option value={"Monthly"}>Monthly</option>
                        <option value={"Yearly"}>Yearly</option>
                      </select>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center w-100">
                    {visaStatus?.datasets?.length ? (
                      <Bar
                        options={options}
                        data={visaStatus}
                        width={700}
                        height={300}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 mb-3 ">
                <div className="bg-light-blue p-4 rounded-4 h-100 ms-2 me-2">
                  <div className="slider-container carousel-width">
                    <Slider {...carouselSettings}>
                      {announcementData?.map((data, index) => (
                        <div className="py-3 px-2 rounded-4 banner-carousel">
                          <div className="d-flex flex-column gap-2">
                            <p className="text2 font-bold text-center mb-0 mt-2">
                              {data.collegeName}
                            </p>
                            <p className="text1-3 text-center">
                              {data.description}
                            </p>
                            <div className="d-flex justify-content-center">
                              <img
                                src={data.bannerImageUrl}
                                alt={data.collegeName}
                                className=" rounded"
                                style={{
                                  height: "230px",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
