import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import StripeKeys from "./StripeKeys";
import apiURLandKEYS from "./apiURLandKEYS";

const ExchangeRate = ({ baseCurrency, targetCurrency, baseAmount }) => {
  const [exchangeRate, setExchangeRate] = useState(null);
  const [convertedAmount, setConvertedAmount] = useState(null);
  const transactionPercentage = StripeKeys.transactionPercentage;

  useEffect(() => {
    const fetchExchangeRates = async () => {
      try {
        const apiUrl = `https://v6.exchangerate-api.com/v6/${apiURLandKEYS.exchangeRateKey}/latest/${baseCurrency}`;
        const response = await axios.get(apiUrl);

        if (response.data.result === "success") {
          const exchangeRates = response.data.conversion_rates;
          const rate = exchangeRates[targetCurrency];
          const updatedRate = (rate * transactionPercentage) / 100 + rate;

          setExchangeRate(updatedRate.toFixed(2));

          if (rate !== undefined) {
            const converted = baseAmount * updatedRate;
            setConvertedAmount(converted.toFixed(2));
          }
        } else {
          toast.error("API response is not successful");
        }
      } catch (error) {
        toast.error("Error fetching exchange rates:", error);
      }
    };
    if (baseCurrency && targetCurrency && baseAmount) {
      fetchExchangeRates();
    }
  }, [baseCurrency, targetCurrency, baseAmount, transactionPercentage]);

  return {
    baseCurrency,
    targetCurrency,
    baseAmount,
    convertedAmount,
    exchangeRate,
  };
};

export default ExchangeRate;
