import { useCallback, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import AgentsDataService from "../../Services/AgentsService";
import CollegeProgramDataService from "../../Services/CollegeProgramService";
import CollegeDataService from "../../Services/CollegeService";
import { ReportsSerice } from "../../Services/ReportsService";
import UserDataService from "../../Services/UserService";
import apiURLandKEYS from "../../Services/apiURLandKEYS";

const graphConfigOptions = {
  responsive: true,
  scales: {
    y: {
      grid: {
        display: false,
      },
      min: 0,
      suggestedMax: 100,
      ticks: {
        beginAtZero: true,
        orecision: 0,
      },

      title: {
        display: true,
        text: "No. of Applications",
        color: "#2e397e",
        font: {
          size: 15,
        },
      },
    },
    x: {
      grid: {
        display: false,
      },
      title: {
        display: true,
        text: "Time Period",
        color: "#2e397e",
        font: {
          size: 15,
        },
      },
    },
  },
  plugins: {
    legend: {
      position: "top",
      labels: {
        usePointStyle: true,
        pointStyle: "circle",
      },
    },
  },
};

export function useReports() {
  const [dropdownData, setDropdownData] = useState({
    colleges: [],
    countries: [],
    programs: [],
    agents: [],
    teamLeaders: [],
  });

  const [openDropdown, setOpenDropdown] = useState({
    colleges: false,
    programs: false,
    agents: false,
  });

  const [inputData, setInputData] = useState({
    colleges: "",
    programs: "",
    agents: "",
  });

  const [graphData, setGraphData] = useState({
    applicationsInCollege: null,
    applicationsInCountry: null,
    applicationsInProgram: null,
    applicationsToAgents: null,
    applicationsToTeamLeaders: null,
  });

  const [loading, setLoading] = useState({
    applicationsInCollege: true,
    applicationsInCountry: true,
    applicationsInProgram: true,
    applicationsToAgents: true,
    applicationsToTeamLeaders: true,
  });

  const [locationAuthToken, setLocationAuthToken] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const getCollegeList = useCallback(
    async (searchQuery) => {
      try {
        setLoading((p) => ({ ...p, applicationsInCollege: true }));
        const resp = await CollegeDataService.find({
          currentPage: 1,
          pageSize: 1000,
          search: searchQuery ?? "",
        });
        const collegeUsefulData = resp.data.response.map((item) => ({
          id: item.id,
          name: item.name,
        }));
        setDropdownData((p) => ({ ...p, colleges: collegeUsefulData }));
        if (!searchParams.get("selectedCollege")) {
          searchParams.set("selectedCollege", collegeUsefulData[0].id);
          setSearchParams(searchParams);
        }
      } catch (err) {
        toast.err(err.message);
      } finally {
        setLoading((p) => ({ ...p, applicationsInCollege: false }));
      }
    },
    [searchParams.get("selectedCollege")]
  );

  const debounce = useCallback((callBack, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        callBack(...args);
      }, delay);
    };
  }, []);

  const getProgramList = useCallback(
    async (searchQuery) => {
      try {
        setLoading((p) => ({ ...p, applicationsInProgram: true }));
        const resp = await CollegeProgramDataService.find({
          currentPage: 1,
          pageSize: 10000,
          search: searchQuery,
        });
        const programNames = resp.data.response.map((item) => ({
          name: item.name,
          id: item.collegeProgramId,
        }));
        setDropdownData((p) => ({ ...p, programs: programNames }));
        if (!searchParams.get("selectedProgram")) {
          searchParams.set("selectedProgram", programNames[0].id);
          setSearchParams(searchParams);
        }
      } catch (err) {
        toast.err(err.message);
      } finally {
        setLoading((p) => ({ ...p, applicationsInProgram: false }));
      }
    },
    [searchParams.get("selectedProgram")]
  );

  const getAgentList = useCallback(
    async (searchQuery) => {
      try {
        setLoading((p) => ({ ...p, applicationsToAgents: true }));
        const resp = await AgentsDataService.find({
          currentPage: 1,
          pageSize: 10000,
          search: searchQuery,
        });
        const agentUsefulData = resp.data.response.map((agent) => ({
          id: agent.id,
          name: agent.name,
        }));
        setDropdownData((p) => ({ ...p, agents: agentUsefulData }));
        if (!searchParams.get("selectedAgent")) {
          searchParams.set("selectedAgent", agentUsefulData[0].id);
          setSearchParams(searchParams);
        }
      } catch (err) {
        toast.err(err.message);
      } finally {
        setLoading((p) => ({ ...p, applicationsToAgents: false }));
      }
    },
    [searchParams.get("selectedAgent")]
  );

  const getTeamLeadersList = useCallback(async () => {
    try {
      setLoading((p) => ({ ...p, applicationsToTeamLeaders: true }));
      const resp = await UserDataService.getTeamLeader();
      setDropdownData((p) => ({ ...p, teamLeaders: resp.data }));
      if (!searchParams.get("selectedTeamLeader")) {
        searchParams.set("selectedTeamLeader", resp.data[0].id);
        setSearchParams(searchParams);
      }
    } catch (err) {
      toast.err(err.message);
    } finally {
      setLoading((p) => ({ ...p, applicationsToTeamLeaders: false }));
    }
  }, [searchParams.get("selectedTeamLeader")]);

  const getLocationAuthToken = useCallback(async () => {
    try {
      const authTokenResponse = await fetch(
        `https://www.universal-tutorial.com/api/getaccesstoken`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "api-token": apiURLandKEYS.getAccessTokenForLocations,
            "user-email": "mandeepin@msn.com",
          },
        }
      );
      const jsonedResponse = await authTokenResponse.json();
      setLocationAuthToken(jsonedResponse.auth_token);
    } catch (err) {
      toast.error("Something went wrong...");
    }
  }, []);

  const getApplicationsForSelectedCollege = useCallback(async () => {
    try {
      setLoading((p) => ({ ...p, applicationsInCollege: true }));
      const response = await ReportsSerice.getApplications({
        filterName: "college",
        keyName: "id",
        value: searchParams.get("selectedCollege"),
      });
      setGraphData((p) => ({
        ...p,
        applicationsInCollege: {
          labels: Object.keys(
            response.data.response.data.StudentApplicationReport
          ),
          datasets: [
            {
              label: "No. of Applications",
              data: Object.values(
                response.data.response.data.StudentApplicationReport
              ),
              backgroundColor: "rgba(46, 57, 126)",
            },
          ],
        },
      }));
    } catch (err) {
      // toast.error(err.message);
    } finally {
      setLoading((p) => ({ ...p, applicationsInCollege: false }));
    }
  }, [searchParams.get("selectedCollege")]);

  const getApplicationsForSelectedProgram = useCallback(async () => {
    try {
      setLoading((p) => ({ ...p, applicationsInProgram: true }));
      const response = await ReportsSerice.getApplications({
        filterName: "program",
        keyName: "id",
        value: searchParams.get("selectedProgram"),
      });
      setGraphData((p) => ({
        ...p,
        applicationsInProgram: {
          labels: Object.keys(
            response.data.response.data.StudentApplicationReport
          ),
          datasets: [
            {
              label: "No. of Applications",
              data: Object.values(
                response.data.response.data.StudentApplicationReport
              ),
              backgroundColor: "rgba(46, 57, 126)",
            },
          ],
        },
      }));
    } catch (err) {
      // toast.error(err.message);
    } finally {
      setLoading((p) => ({ ...p, applicationsInProgram: false }));
    }
  }, [searchParams.get("selectedProgram")]);

  const getApplicationsForTeamLeader = useCallback(async () => {
    try {
      setLoading((p) => ({ ...p, applicationsToTeamLeaders: true }));
      const response = await ReportsSerice.getApplications({
        filterName: "teamleader",
        keyName: "id",
        value: searchParams.get("selectedTeamLeader"),
      });
      setGraphData((p) => ({
        ...p,
        applicationsToTeamLeaders: {
          labels: Object.keys(
            response.data.response.data.StudentApplicationReport
          ),
          datasets: [
            {
              label: "No. of Applications",
              data: Object.values(
                response.data.response.data.StudentApplicationReport
              ),
              backgroundColor: "rgba(46, 57, 126)",
            },
            {
              label: "Tuition Fee Deposit",
              data: Object.values(
                response.data.response.data.StudentApplicationFeePaidReport
              ),
              backgroundColor: "#94B6CD",
            },
          ],
        },
      }));
    } catch (err) {
      // toast.error(err.message);
    } finally {
      setLoading((p) => ({ ...p, applicationsToTeamLeaders: false }));
    }
  }, [searchParams.get("selectedTeamLeader")]);

  const getApplicationsForCountry = useCallback(async () => {
    try {
      setLoading((p) => ({ ...p, applicationsInCountry: true }));
      const response = await ReportsSerice.getApplications({
        filterName: "country",
        keyName: "name",
        value: searchParams.get("selectedCountry"),
      });
      setGraphData((p) => ({
        ...p,
        applicationsInCountry: {
          labels: Object.keys(
            response.data.response.data.StudentApplicationReport
          ),
          datasets: [
            {
              label: "No. of Applications",
              data: Object.values(
                response.data.response.data.StudentApplicationReport
              ),
              backgroundColor: "rgba(46, 57, 126)",
            },
          ],
        },
      }));
    } catch (err) {
      // toast.error(err.message);
    } finally {
      setLoading((p) => ({ ...p, applicationsInCountry: false }));
    }
  }, [searchParams.get("selectedCountry")]);

  const getApplicationsForAgent = useCallback(async () => {
    try {
      setLoading((p) => ({ ...p, applicationsToAgents: true }));
      const response = await ReportsSerice.getApplications({
        filterName: "agent",
        keyName: "id",
        value: searchParams.get("selectedAgent"),
      });
      setGraphData((p) => ({
        ...p,
        applicationsToAgents: {
          labels: Object.keys(
            response.data.response.data.StudentApplicationReport
          ),
          datasets: [
            {
              label: "No. of Applications",
              data: Object.values(
                response.data.response.data.StudentApplicationReport
              ),
              backgroundColor: "rgba(46, 57, 126)",
            },
            {
              label: "Tuition Fee Deposit",
              data: Object.values(
                response.data.response.data.StudentApplicationFeePaidReport
              ),
              backgroundColor: "#94B6CD",
            },
          ],
        },
      }));
    } catch (err) {
      // toast.error(err.message);
    } finally {
      setLoading((p) => ({ ...p, applicationsToAgents: false }));
    }
  }, [searchParams.get("selectedAgent")]);

  const handleCollegeDebouncedSearch = useCallback(
    debounce((search) => getCollegeList(search), 500),
    []
  );
  const handleProgramsDebouncedSearch = useCallback(
    debounce((search) => getProgramList(search), 500),
    []
  );
  const handleAgentsDebouncedSearch = useCallback(
    debounce((search) => getAgentList(search), 500),
    []
  );

  const handleChange = useCallback(
    async (search, type) => {
      setInputData((p) => ({
        ...p,
        [type]: search,
      }));
      if (type === "colleges") {
        handleCollegeDebouncedSearch(search);
      } else if (type === "programs") {
        handleProgramsDebouncedSearch(search);
      } else if (type === "agents") {
        handleAgentsDebouncedSearch(search);
      }
    },
    [
      handleAgentsDebouncedSearch,
      handleCollegeDebouncedSearch,
      handleProgramsDebouncedSearch,
    ]
  );

  useEffect(() => {
    if (!searchParams.get("selectedCollege")) {
      return;
    }
    getApplicationsForSelectedCollege();
  }, [searchParams.get("selectedCollege")]);

  useEffect(() => {
    if (!searchParams.get("selectedProgram")) {
      return;
    }
    getApplicationsForSelectedProgram();
  }, [searchParams.get("selectedProgram")]);

  useEffect(() => {
    if (!searchParams.get("selectedTeamLeader")) {
      return;
    }
    getApplicationsForTeamLeader();
  }, [searchParams.get("selectedTeamLeader")]);

  useEffect(() => {
    if (!searchParams.get("selectedCountry")) {
      return;
    }
    getApplicationsForCountry();
  }, [searchParams.get("selectedCountry")]);

  useEffect(() => {
    if (!searchParams.get("selectedAgent")) {
      return;
    }
    getApplicationsForAgent();
  }, [searchParams.get("selectedAgent")]);

  useEffect(() => {
    if (!locationAuthToken) {
      return;
    }
    async function getCountries() {
      try {
        setLoading((p) => ({ ...p, countries: true }));
        const countriesResponse = await fetch(
          `https://www.universal-tutorial.com/api/countries/`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${locationAuthToken}`,
              Accept: "application/json",
            },
          }
        );
        const jsonedResponse = await countriesResponse.json();
        setDropdownData((p) => ({ ...p, countries: jsonedResponse }));
        if (!searchParams.get("selectedCountry")) {
          searchParams.set("selectedCountry", jsonedResponse[0].country_name);
          setSearchParams(searchParams);
        }
      } catch (err) {
        toast.error("Something went wrong while fetching countries...");
      } finally {
        setLoading((p) => ({ ...p, countries: false }));
      }
    }
    getCountries();
  }, [locationAuthToken]);

  useEffect(() => {
    getCollegeList();
    getProgramList();
    getAgentList();
    getTeamLeadersList();
  }, []);
  console.log({ inputData });

  const runOnce = useRef(false);
  const programOnce = useRef(false);
  const agentOnce = useRef(false);
  useEffect(() => {
    if (
      runOnce.current ||
      !searchParams.get("selectedCollege") ||
      !dropdownData.colleges.length
    ) {
      return;
    }

    setInputData((p) => ({
      ...p,
      colleges:
        dropdownData.colleges.find(
          (college) => college.id === searchParams.get("selectedCollege")
        )?.name ?? "",
    }));
    runOnce.current = true;
  }, [dropdownData.colleges, searchParams.get("selectedCollege")]);

  useEffect(() => {
    if (
      programOnce.current ||
      !searchParams.get("selectedProgram") ||
      !dropdownData.programs.length
    ) {
      return;
    }

    setInputData((p) => ({
      ...p,
      programs:
        dropdownData.programs.find(
          (college) => college.id === searchParams.get("selectedProgram")
        )?.name ?? "",
    }));
    programOnce.current = true;
  }, [dropdownData.programs, searchParams.get("selectedProgram")]);

  useEffect(() => {
    if (
      agentOnce.current ||
      !searchParams.get("selectedAgent") ||
      !dropdownData.agents.length
    ) {
      return;
    }

    setInputData((p) => ({
      ...p,
      agents:
        dropdownData.agents.find(
          (college) => college.id === searchParams.get("selectedAgent")
        )?.name ?? "",
    }));
    agentOnce.current = true;
  }, [dropdownData.agents, searchParams.get("selectedAgent")]);

  useEffect(() => {
    getLocationAuthToken();
  }, []);

  return {
    dropdownData,
    graphConfigOptions,
    graphData,
    loading,
    setInputData,
    inputData,
    getCollegeList,
    handleChange,
    openDropdown,
    setOpenDropdown,
  };
}
