import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import CollegeDataService from "../../Services/CollegeService";
import IntakeService from "../../Services/IntakeService";
import ProgramSearchService from "../../Services/ProgramSearchService";
import shareIcon from "../../assets/icons/share.svg";
import companyLogo from "../../assets/logo_dark.png";
import ShareButton from "../../components/ShareButton";
import college from "../../assets/programDetailPage/college.svg";
import ranking from "../../assets/programDetailPage/ranking.svg";
import province from "../../assets/programDetailPage/province.svg";
import affiliation from "../../assets/programDetailPage/affiliation.svg";
import country from "../../assets/programDetailPage/country.svg";
import license from "../../assets/programDetailPage/license.svg";
import pg from "../../assets/programDetailPage/pg.svg";
import fees from "../../assets/programDetailPage/fees.svg";
import url from "../../assets/programDetailPage/url.svg";
import credential from "../../assets/programDetailPage/credential.svg";
import duration from "../../assets/programDetailPage/duration.svg";
import campusLogo from "../../assets/programDetailPage/campus.svg";
import openLogo from "../../assets/programDetailPage/open.svg";
import yet from "../../assets/programDetailPage/yet.svg";
import academic from "../../assets/programDetailPage/academic.svg";
import waitlist from "../../assets/programDetailPage/waitlist.svg";
import pal from "../../assets/programDetailPage/pal.svg";
import english from "../../assets/programDetailPage/english.svg";
import program from "../../assets/programDetailPage/program.svg";

import Header from "../../layout/Header";
import {
  capitalizeFirstLetter,
  floatValue,
  formatIntakeDate,
} from "../../shared/helpers";
import "./DetailedProgram.css";
import Enquire from "./Enquire";
import EpLoader from "../../components/UI/EpLoader";

const DetailCard = ({ detailKey, data, detailObject, setLicenseModalShow }) => {
  const iconName = detailObject[detailKey]?.icon;
  return (
    <div className="detail-card bg-light-blue border-bottom p-4">
      <div className="d-flex gap-4 align-items-center">
        <div className="bg-white detail-logo p-1 d-flex justify-content-center align-items-center">
          <img className="logo-icon" src={iconName} alt="icon" />
        </div>
        <p className="text1-4 mb-0">
          <span>{detailObject[detailKey]?.keyName} </span> -{" "}
          <span className=" fw-bold">
            {PRICE_FIELDS?.includes(detailKey) ? (
              `CAD ${floatValue(data[detailKey])}`
            ) : COURSE_DETAILS_TO_MAP_LINK_FIELDS?.includes(detailKey) ? (
              <a
                href={data[detailKey]}
                alt={detailObject[detailKey]?.keyName}
                target="_blank"
                rel="noreferrer"
              >
                {detailObject[detailKey]?.text}
              </a>
            ) : data[detailKey] === 0 ? (
              0
            ) : detailKey === "regionsRestricted" ? (
              data[detailKey].length ? (
                <span
                  style={{
                    cursor: "pointer",
                  }}
                  className="text-break"
                  onClick={() => setLicenseModalShow(true)}
                >
                  {data[detailKey].join(", ")}
                </span>
              ) : (
                "None"
              )
            ) : (
              data[detailKey] || "None"
            )}
          </span>
        </p>
      </div>
    </div>
  );
};

export default function DetailedProgram() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [getDataFailed, setGetDataFailed] = useState(false);
  const [programData, setProgramData] = useState(null);
  const [currentTab, setCurrentTab] = useState("overview");
  const [enquireModalShow, setEnquireModalShow] = useState(false);
  const [licenseModalShow, setLicenseModalShow] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [unifiedProgramAndCollegeData, setUnifiedProgramAndCollegeData] =
    useState(null);

  const handleCollegeAndProgram = useCallback(async () => {
    try {
      const programResponse = await ProgramSearchService.get(id);
      const collegeResponse = await CollegeDataService.get(
        programResponse.data.collegeId
      );

      const intakeData = {
        collegeId: programResponse.data.collegeId,
        collegeProgramId: id,
      };
      const intakeResponse =
        await IntakeService.getListByCollegeIdAndCollegeProgramId(intakeData);

      const regionsRestricted = collegeResponse?.data?.regionsRestricted.map(
        (region) => Object.values(region)[0]
      );

      setLoading(false);
      // setIntakeData(intakeResponseData);
      setProgramData(programResponse.data);
      // setCollegeData(collegeResponse.data);
      setUnifiedProgramAndCollegeData({
        ...programResponse.data,
        ...collegeResponse.data,
        programName: programResponse.data.name,
        programId: programResponse.data.id,
        collegeName: collegeResponse.data.name,
        collegeId: collegeResponse.data.id,
        regionsRestricted: regionsRestricted,
        campus: programResponse.data.campus.map((campus) => ({
          ...campus,
          openIntake: intakeResponse?.data
            ?.filter(
              (intake) =>
                intake.statusId === 3 && campus.id === intake.collegeCampusId
            )
            ?.sort((a, b) => new Date(a.date) - new Date(b.date))
            ?.map((intake) => formatIntakeDate(intake?.date))
            ?.join(", "),
          yetToOpenIntake: intakeResponse?.data
            ?.filter(
              (intake) =>
                intake.statusId === 2 && campus.id === intake.collegeCampusId
            )
            ?.sort((a, b) => new Date(a.date) - new Date(b.date))
            ?.map((intake) => formatIntakeDate(intake?.date))
            ?.join(", "),
          waitlistIntake: intakeResponse?.data
            ?.filter(
              (intake) =>
                intake.statusId === 4 && campus.id === intake.collegeCampusId
            )
            ?.sort((a, b) => new Date(a.date) - new Date(b.date))
            ?.map((intake) => formatIntakeDate(intake?.date))
            ?.join(", "),
        })),
      });
      document.title = programResponse.data.name;
    } catch (error) {
      setLoading(false);
      setGetDataFailed(true);
    }
  }, [id]);

  useEffect(() => {
    handleCollegeAndProgram();
  }, [handleCollegeAndProgram]);

  const openEnquireModal = (program) => {
    setSelectedProgram(program);
    setEnquireModalShow(true);
  };

  const closeModal = () => {
    setSelectedProgram(null);
    setEnquireModalShow(false);
  };

  if (loading) {
    return <EpLoader />;
  }

  return (
    <div style={{ position: "relative" }}>
      <Header isScroll={true} />
      {getDataFailed ? (
        "Not Found"
      ) : (
        <div>
          <div
            style={{
              paddingTop: "7rem",
              paddingBottom: "3.5rem",
            }}
            className="bgImage"
          >
            <div className="container mt-0 mt-md-5 py-md-5 ps-3 ps-sm-0">
              <div className="d-flex  gap-5  align-items-center">
                <div className="w-100 w-md-50">
                  <div className="d-flex flex-column gap-2 gap-md-4">
                    <p
                      className="text1 mb-0 text-white"
                      style={{
                        fontWeight: 400,
                      }}
                    >
                      {unifiedProgramAndCollegeData?.collegeName}
                    </p>
                    <a
                      style={{
                        textDecoration: "none",
                      }}
                      target="_blank"
                      rel="noreferrer"
                      href={programData?.courseUrl}
                      alt="course url"
                    >
                      <p
                        style={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          WebkitLineClamp: 3,
                          maxWidth: "100%",
                        }}
                        className="programName hero-text mb-0 mt-0 lh-sm text-white fw-bold"
                      >
                        {" "}
                        {programData?.name}
                      </p>
                    </a>
                  </div>
                </div>
                <div className="w-100 w-md-50 mt-2 d-flex justify-content-end ">
                  <div className="bg-white px-3 py-2 rounded-4 ">
                    <img
                      src={
                        unifiedProgramAndCollegeData?.detailPageLogo ||
                        companyLogo
                      }
                      alt="college logo"
                      className="college-logo ml-auto"
                      style={{
                        maxWidth: "160px",
                        maxHeight: "100%",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Tabs
              activeKey={currentTab}
              onSelect={(t) => setCurrentTab(t)}
              className=" bg-light-blue ps-5  mb-3 py-4 text1-4 d-flex flex-nowrap overflow-auto"
              variant="underline"
              justify
            >
              <Tab
                tabClassName={
                  currentTab === "overview" ? "tab-active" : "tab-Detail"
                }
                eventKey="overview"
                title="Overview"
              >
                <div className="container my-2 my-sm-4 py-3 py-sm-4">
                  <Row xs={1} md={4} className="gx-5 gy-3">
                    {Object.keys(Overview_Details).map((key) => (
                      <Col md={6} key={key}>
                        <DetailCard
                          data={unifiedProgramAndCollegeData}
                          detailKey={key}
                          detailObject={Overview_Details}
                          setLicenseModalShow={setLicenseModalShow}
                        />
                      </Col>
                    ))}
                  </Row>
                </div>
              </Tab>
              <Tab
                tabClassName={
                  currentTab === "courseDetails" ? "tab-active" : "tab-Detail"
                }
                eventKey="courseDetails"
                title="Course Details"
              >
                <div className="container my-2 my-sm-4 py-3 py-sm-4">
                  <Row xs={1} md={4} className="gx-5 gy-3">
                    {Object.keys(Course_Details).map((key) => (
                      <Col md={6} key={key}>
                        <DetailCard
                          data={unifiedProgramAndCollegeData}
                          detailKey={key}
                          detailObject={Course_Details}
                          setLicenseModalShow={setLicenseModalShow}
                        />
                      </Col>
                    ))}
                  </Row>
                </div>
              </Tab>
              <Tab
                tabClassName={
                  currentTab === "campusIntake" ? "tab-active" : "tab-Detail"
                }
                eventKey="campusIntake"
                title="Campus and Intake"
                onOverflow={"scroll"}
              >
                <div className="container intake-container my-3 my-sm-5 d-flex flex-column gap-4 py-2">
                  {unifiedProgramAndCollegeData.campus?.map((item, idx) => (
                    <Row
                      key={idx}
                      xs={1}
                      md={4}
                      className="d-flex justify-content-between gy-3 campus-card-row gy-md-0 pb-3 mt-2 mt-sm-0 rounded-4 pb-md-0"
                    >
                      <div className="d-flex ">
                        <div className="w-100 detail-card bg-light-blue border-bottom p-4">
                          <div className="d-flex gap-4 align-items-center">
                            <div className="bg-white detail-logo p-1 d-flex justify-content-center align-items-center">
                              <img
                                className="logo-icon"
                                src={campusLogo}
                                alt="icon"
                              />
                            </div>
                            <p className="text1-4 mb-0">
                              <span> Campus </span> -{" "}
                              <span className=" fw-bold">{item?.name}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="w-100 detail-card bg-light-blue border-bottom p-4">
                          <div className="d-flex gap-4 align-items-center">
                            <div className="bg-white detail-logo p-1 d-flex justify-content-center align-items-center">
                              <img
                                className="logo-icon"
                                src={openLogo}
                                alt="icon"
                              />
                            </div>
                            <p className="text1-4 mb-0">
                              <span> Open Intake </span> -{" "}
                              <span className=" fw-bold">
                                {item.openIntake
                                  ? item.openIntake
                                  : "No Intakes"}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="w-100 detail-card bg-light-blue border-bottom p-4">
                          <div className="d-flex gap-4 align-items-center">
                            <div className="bg-white detail-logo p-1 d-flex justify-content-center align-items-center">
                              <img className="logo-icon" src={yet} alt="icon" />
                            </div>
                            <p className="text1-4 mb-0">
                              <span> Yet to open </span> -{" "}
                              <span className=" fw-bold">
                                {item.yetToOpenIntake
                                  ? item.yetToOpenIntake
                                  : "No Intakes"}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="w-100 detail-card bg-light-blue border-bottom p-4">
                          <div className="d-flex gap-4 align-items-center">
                            <div className="bg-white detail-logo p-1 d-flex justify-content-center align-items-center">
                              <img
                                className="logo-icon"
                                src={waitlist}
                                alt="icon"
                              />
                            </div>
                            <p className="text1-4 mb-0">
                              <span> Waitlisted </span> -{" "}
                              <span className=" fw-bold">
                                {item.waitlistIntake
                                  ? item.waitlistIntake
                                  : "No Intakes"}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Row>
                  ))}
                </div>
              </Tab>
              <Tab
                tabClassName={
                  currentTab === "admission" ? "tab-active" : "tab-Detail"
                }
                eventKey="admission"
                title="Admission Requirements"
              >
                <div className="container intake-container my-2 my-sm-4 d-flex flex-column gap-3 gap-md-4  py-2 py-sm-4">
                  <Row className="d-flex gy-4  justify-content-between">
                    <div className="d-flex  admission-card">
                      <div className="w-100 detail-card bg-light-blue border-bottom p-4">
                        <div className="d-flex flex-sm-row flex-column gap-4 align-items-sm-center">
                          <div className="bg-white detail-logo p-1 d-flex justify-content-center align-items-center">
                            <img
                              className="logo-icon"
                              src={academic}
                              alt="icon"
                            />
                          </div>
                          <p className="text1-4 mb-0 d-flex flex-sm-row flex-column">
                            <span> Academic Background - </span>
                            <span className=" fw-bold">
                              {unifiedProgramAndCollegeData?.academicRequirement
                                ? unifiedProgramAndCollegeData?.academicRequirement
                                : "None"}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex w-100 w-md-50 ">
                      <div className="w-100 detail-card bg-light-blue border-bottom p-4">
                        <div className="d-flex flex-sm-row flex-column gap-4 align-items-sm-center">
                          <div className="bg-white detail-logo p-1 d-flex justify-content-center align-items-center">
                            <img className="logo-icon" src={pal} alt="icon" />
                          </div>
                          <p className="text1-4 mb-0 d-flex flex-column d-sm-block">
                            <span> PAL/Other Information - </span>
                            <span className=" fw-bold">
                              {unifiedProgramAndCollegeData?.specificRequirement1
                                ? unifiedProgramAndCollegeData.specificRequirement1
                                : "N/A"}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <Row className="d-flex gy-4  justify-content-between">
                    <div className="d-flex admission-card ">
                      <div className="w-100 detail-card bg-light-blue border-bottom p-4">
                        <div className="d-flex gap-4 flex-sm-row flex-column">
                          <div className="bg-white detail-logo p-1 d-flex justify-content-center align-items-center">
                            <img
                              className="logo-icon"
                              src={english}
                              alt="icon"
                            />
                          </div>
                          <p className="text1-4 mb-0 d-flex flex-column d-sm-block">
                            <span>
                              {" "}
                              English Proficiency Test/Other Test Requirement -{" "}
                            </span>

                            <ul type="none" className="ps-0">
                              {Object.keys(COURSE_ENGLISH_PROFICIENCY).map(
                                (key) => (
                                  <li className="text1-4 mb-0  fw-bold">
                                    <span>
                                      {COURSE_ENGLISH_PROFICIENCY[key]} -{" "}
                                    </span>
                                    <span>
                                      {unifiedProgramAndCollegeData[key]
                                        ? unifiedProgramAndCollegeData[key]
                                        : 0}
                                    </span>
                                  </li>
                                )
                              )}
                            </ul>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex w-100 w-md-50 ">
                      <div className="w-100 detail-card bg-light-blue border-bottom p-4">
                        <div className="d-flex gap-4 flex-sm-row flex-column align-items-sm-center">
                          <div className="bg-white detail-logo p-1 d-flex justify-content-center align-items-center">
                            <img
                              className="logo-icon"
                              src={program}
                              alt="icon"
                            />
                          </div>
                          <p className="text1-4 mb-0 d-flex flex-column d-sm-block">
                            <span> Program Specific Requirement - </span>
                            <span className=" fw-bold">
                              {unifiedProgramAndCollegeData?.specificRequirement2
                                ? unifiedProgramAndCollegeData.specificRequirement2
                                : "N/A"}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      )}
      <div className="floating-enquiry-button">
        <Button
          variant="primary"
          className="rounded-circle enquiry-button font-bold text1-1"
          onClick={() => openEnquireModal(programData)}
        >
          Enquire Now
        </Button>
      </div>
      {selectedProgram && (
        <Modal
          show={enquireModalShow}
          onHide={closeModal}
          centered
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header className="bg-dark1 text-white d-flex justify-content-between align-items-center py-2">
            <Modal.Title className="d-flex align-items-center">
              Please fill the form below to enquire
            </Modal.Title>
            <i
              className="bi bi-x-circle text2"
              style={{ cursor: "pointer" }}
              onClick={() => setEnquireModalShow(false)}
            ></i>
          </Modal.Header>
          <Modal.Body>
            <Enquire
              program={selectedProgram}
              setModalShow={setEnquireModalShow}
            />
          </Modal.Body>
        </Modal>
      )}
      <Modal
        show={licenseModalShow}
        onHide={() => setLicenseModalShow(false)}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        maxWidth="sm"
        className="custom-modal"
      >
        <Modal.Body>
          <i
            className="bi bi-x-circle-fill text2 p-3 text-danger position-absolute end-0 top-0"
            style={{ cursor: "pointer" }}
            onClick={() => setLicenseModalShow(false)}
          ></i>
          <div className="d-flex flex-column  align-items-center pt-5 gap-4">
            <img src={companyLogo} alt="platformLogo" height="50px" />
            <p className="text1-8  fw-bold">License Limitations</p>
          </div>
          <ul className="ps-5 ms-3 mb-4 mt-3">
            {unifiedProgramAndCollegeData?.regionsRestricted?.map(
              (item, idx) => (
                <li className="text1-5 fw-bold  mt-2">{item}</li>
              )
            )}
          </ul>
        </Modal.Body>
      </Modal>
    </div>
  );
}

const Overview_Details = {
  collegeName: {
    keyName: "Institution",
    icon: college,
  },
  country: {
    keyName: "Country",
    icon: country,
  },

  province: {
    keyName: "Province",
    icon: province,
  },

  directIndirect: {
    keyName: "Affiliation",
    icon: affiliation,
  },
  qsRanking: {
    keyName: "QS Ranking",
    icon: ranking,
  },
  regionsRestricted: {
    keyName: "License Restrictions",
    icon: license,
  },
  pgWorkPermit: {
    keyName: "PG Work Permit",
    icon: pg,
  },
  website: {
    keyName: "Website Url",
    text: "Website Link",
    icon: url,
  },
};
const Course_Details = {
  credential: {
    keyName: "Credential",
    icon: credential,
  },
  applicationFee: {
    keyName: "Application Fees",
    icon: fees,
  },
  duration: {
    keyName: "Duration",
    icon: duration,
  },
  tuitionFee: {
    keyName: "Tuition Fees",
    icon: fees,
  },

  courseUrl: {
    keyName: "Course Url",
    text: "Course Link",
    icon: url,
  },
  refundUrl: {
    keyName: "Refund Url",
    text: "Tuition Fees Refund Link",
    icon: url,
  },
};

const PRICE_FIELDS = ["tuitionFee", "applicationFee"];

const COURSE_DETAILS_TO_MAP_LINK_FIELDS = ["website", "refundUrl", "courseUrl"];

const COURSE_ENGLISH_PROFICIENCY = {
  ieltsRequirement: "IELTS",
  pteRequirement: "PTE",
  duolingoRequirement: "Duolingo",
  toeflRequirement: "TOEFL",
};
