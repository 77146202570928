import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import WelcomeBanner from "./WelcomeBanner";
import ApplicationService from "../../Services/ApplicationService";
import ProgramSearchService from "../../Services/ProgramSearchService";

import { toast } from "react-toastify";
import SearchProgramToAdd from "./SearchProgramToAdd";
import SearchAndSelectProgramManually from "./SearchAndSelectProgramManually";
import PageTitle from "../../components/PageTitle";

export default function ApplicationForm({ studentData, allDraftApplication }) {
  let navigate = useNavigate();

  const [selectedResult, setSelectedResults] = useState([]);
  const [selectedIntake, setSelectedIntake] = useState("");
  const [country, setCountry] = useState("");
  const [applications, setApplications] = useState([]);
  const getAllApplication = async () => {
    if (studentData.id) {
      try {
        const response =
          await ApplicationService.getStudentApplicationByStudentId(
            studentData.id
          );
        setApplications(response.data.response);
      } catch (error) {
        //toast.error("Error fetching applications");
      }
    }
  };

  const [selectedCampusInApplication, setSelectedCampusInApplication] =
    useState("");

  useEffect(() => {
    getAllApplication();
  }, []);

  const saveApplicationData = async (e) => {
    e.preventDefault();
    const isDuplicate = applications.some(
      (application) => application.collegeProgramId === selectedResult.id
    );

    if (!selectedResult.id) {
      toast.error(`Program Not selected properly`);
      return;
    } else if (isDuplicate) {
      toast.error(
        `You have already applied for "${
          selectedResult.collegeProgramName === undefined
            ? selectedResult.name
            : selectedResult.collegeProgramName
        }"`
      );
      return;
    } else if (studentData.passportNumber === null) {
      toast.error("Passport Number is must to start a application");
      return;
    } else {
      const data = {
        statusId: 1,
        studentId: studentData.id,
        collegeId: selectedResult.collegeId,
        collegeProgramId: selectedResult.id,
        country: country,
        campus: selectedCampusInApplication,
        intake: selectedIntake,
        remarks: "Application Created",
        applicationStatus: "Draft",
      };

      try {
        const response = await ApplicationService.create(data);
        toast.success(response.data.message);
        navigate("/user/viewApplication/" + response.data.response);
      } catch (error) {
        toast.error("Error saving application");
      }
    }
  };

  const isDisabled =
    selectedResult.length === 0 ||
    (selectedResult.intake && selectedResult.intake.includes("no intake"));
  return (
    <>
      <WelcomeBanner data={studentData} isData={true} show={false} />

      <form className="needs-validation" onSubmit={saveApplicationData}>
        <SearchAndSelectProgramManually
          setSelectedResults={setSelectedResults}
          saveApplicationData={saveApplicationData}
          selectedIntake={selectedIntake}
          setSelectedIntake={setSelectedIntake}
          setSelectedCampusInApplication={setSelectedCampusInApplication}
          setCountry={setCountry}
          // isNextDisabled={isNextDisabled}
        />

        <SearchProgramToAdd
          setSelectedResults={setSelectedResults}
          studentData={studentData}
          // isNextDisabled={isNextDisabled}
        />

        {/* <div className="d-flex align-items-center justify-content-center">
          <div>
            <button type="submit" className="btn btn-dark-blue rounded-pill py-3 text1-4" disabled={isDisabled}>
              Start Selected Program
            </button>
          </div>
          <div
            className="text-center text1-5 bg-orange rounded-circle d-flex align-items-center justify-content-center text-white mx-4"
            style={{ width: "40px", height: "40px" }}
          >
            OR
          </div>
          <button type="button" className="btn btn-dark-blue rounded-pill py-3 text1-4" onClick={showProgramList}>
            Show All Programs List
          </button>
        </div> */}
      </form>
    </>
  );
}
