import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import AustraliaFlag from "../../assets/common/Australia.svg";
import IndiaFlag from "../../assets/common/India.svg";
import Ireland from "../../assets/common/Ireland.svg";
import UKFlag from "../../assets/common/UK.svg";
import USAFlag from "../../assets/common/USA.svg";
import CanadaFlag from "../../assets/common/canada.svg";
import globeIcon from "../../assets/common/globe-asia-australia.svg";
import NewZealandFlag from "../../assets/common/newZealand.svg";
import ProgramSearchService from "../../Services/ProgramSearchService";
import { useComponentVisible } from "../../shared/hooks/useComponentVisible";
import { useLocation } from "react-router-dom";
import { ListGroup } from "react-bootstrap";

export default function MainSearch({
  searchQuery,
  setSearchQuery,
  handleHomeProgramSearch,
  pageTitle,
  selectedCountry,
  setSelectedCountry,
  placeholder = "Program/Institute Search",
  isInputClicked,
  setIsInputClicked,
  setCurrentPage,
  bgColor,
}) {
  const navValue = useLocation();

  const navSearch = navValue.state?.searchQuery;
  const navInput = navValue.state?.input;
  const [allData, setAllData] = useState([]);
  const [options, setOptions] = useState(allData);
  const [input, setInput] = useState(navSearch || "");
  const countryList = [
    {
      name: "All",
      value: "All",
      flagImage: globeIcon,
    },
    {
      name: "India",
      value: "India",
      flagImage: IndiaFlag,
    },
    {
      name: "Canada",
      value: "Canada",
      flagImage: CanadaFlag,
    },
    {
      name: "Australia",
      value: "Australia",
      flagImage: AustraliaFlag,
    },
    {
      name: "USA",
      value: "USA",
      flagImage: USAFlag,
    },
    {
      name: "UK",
      value: "UK",
      flagImage: UKFlag,
    },
    {
      name: "New Zealand",
      value: "New Zealand",
      flagImage: NewZealandFlag,
    },
    {
      name: "Ireland",
      value: "Ireland",
      flagImage: Ireland,
    },
  ];

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible();
  const filteredOptions = useCallback(
    (search) => {
      if (search) {
        const options = allData?.filter((data) => {
          return data.toLowerCase().includes(search.toLowerCase());
        });
        setOptions(options);
        return;
      }
      setOptions(allData);
    },
    [allData]
  );
  const getSearchData = useCallback(async () => {
    try {
      const resp = await ProgramSearchService.getAllCollegesAndProgramsList();
      setAllData(resp.data.response);
    } catch (err) {
      toast.err(err.message);
    }
  }, []);

  useEffect(() => {
    getSearchData();
  }, [getSearchData]);
  useEffect(() => {
    setOptions(allData);
    if (navSearch) {
      filteredOptions(navSearch);
    }
  }, [allData]);

  const handleCountryChange = (value) => {
    setSelectedCountry((prevSelectedOptions) => {
      const allCountries = countryList.map((country) => country.name);

      if (value === "All") {
        if (prevSelectedOptions.length < allCountries.length) {
          // If "All" is selected and not all countries are already selected, select all countries
          return allCountries;
        } else {
          // If "All" is selected and all countries are already selected, deselect all countries
          return ["Canada"];
        }
      }

      if (prevSelectedOptions.includes(value)) {
        // If the option is already selected, deselect it
        const updatedSelectedOptions = prevSelectedOptions.filter(
          (item) => item !== value
        );

        // Check if there are no selected countries left
        if (updatedSelectedOptions.length === 0) {
          toast.info("Minimum one country is required.");
          return prevSelectedOptions; // Prevent deselection
        }

        // If "All" is selected, deselect it
        const updatedOptions = updatedSelectedOptions.filter(
          (item) => item !== "All"
        );
        return updatedOptions;
      } else {
        // If the option is not selected, select it
        return [...prevSelectedOptions, value];
      }
    });
  };

  // Find the flag image based on the selectedCountry value
  const selectedCountryData = countryList?.find((country) =>
    selectedCountry?.includes(country.name)
  );

  const flagImage =
    selectedCountryData && selectedCountry.length === 1
      ? selectedCountryData.flagImage
      : globeIcon;

  const handleSearch = useCallback(
    (event) => {
      const query = event.target.value;
      setInput(query);
      filteredOptions(query);
      if (setCurrentPage) {
        setCurrentPage(1);
      }
    },
    [filteredOptions, setCurrentPage]
  );

  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center gap-4 mx-4">
        <div
          style={{
            width: "65%",
          }}
          className={`d-flex justify-content-center px-2 py-0 dropdown rounded-pill text1-3 ${
            bgColor ? bgColor : "bg-light0"
          } shadow border-1 border`}
        >
          <div
            className="dropdown-toggle d-flex align-items-center w-100"
            type="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
          >
            <img
              src={flagImage}
              alt="flag"
              className="rounded-circle me-1 py-2"
              width={"28px"}
            />
            <span
              className="text-truncate d-flex justify-content-center"
              style={{ width: "160px" }}
            >
              {selectedCountry?.join(", ")}
            </span>
          </div>
          <ul className="dropdown-menu w-100">
            {countryList.map((country) => (
              <div key={country.name}>
                <li
                  style={{
                    cursor: "pointer",
                  }}
                  className={`countrySelectedDropDownItem border-top text1-3`}
                  onClick={() => handleCountryChange(country.name)}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div>{country.name}</div>
                    <i
                      className={`bi bi-check fs-3 m-0 p-0 d-flex justify-content-between align-items-center ${
                        selectedCountry?.includes(country.name)
                          ? "text-success"
                          : "text-white"
                      }`}
                    ></i>
                  </div>
                </li>
              </div>
            ))}
          </ul>
        </div>
        <div
          className={`${
            bgColor ? bgColor : "bg-light0"
          } overflow-hidden position-relative p-2 dropdown w-100 rounded-pill  text1-3  shadow border-1 border`}
          onClick={() => {
            setIsComponentVisible(!isComponentVisible);
            setIsInputClicked(true);
          }}
        >
          {isInputClicked || searchQuery ? null : (
            <span
              className={`${
                bgColor ? bgColor : "bg-light0"
              } overflow-hidden animated-placeholder position-absolute ms-3 text1-4 opacity-50 w-75`}
              style={{
                pointerEvents: "none",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {placeholder}
            </span>
          )}
          <input
            type="text"
            className={` ${
              bgColor ? bgColor : "bg-light0"
            } form-control border-0 ms-2 py-3 formControl text1-4 rounded-pill`}
            ref={ref}
            style={{ width: "calc(100% - 50px)" }}
            value={input}
            onChange={handleSearch}
            onBlur={() => {
              if (!input) setIsInputClicked(false);
            }}
          />

          <div className="top-50 end-0 position-absolute top-50 end-0 translate-middle-y me-3">
            <button
              className="btn btn-dark-blue rounded-circle"
              style={{ width: "32px", height: "32px" }}
              onClick={() => {
                if (handleHomeProgramSearch) {
                  handleHomeProgramSearch(input);
                } else {
                  setSearchQuery(input);
                  if (setCurrentPage) {
                    setCurrentPage(1);
                  }
                }
              }}
            >
              <span className="d-flex justify-content-center align-items-center text1-7">
                <i
                  className={`bi bi-search justify-content-center align-items-center`}
                ></i>
              </span>
            </button>
          </div>
        </div>
        <div className="d-flex w-100 position-absolute bottom-0 ps-3  justify-content-center mx-5">
          {isComponentVisible ? (
            <ListGroup
              style={{
                maxHeight: "200px",
              }}
              className="dropdown-options-program bg-white shadow"
            >
              {options?.length ? (
                <>
                  {options?.map((item, index) => (
                    <ListGroup.Item
                      className="text1-4 list-item"
                      style={{
                        cursor: "pointer",
                      }}
                      key={index}
                      onClick={() => {
                        if (!item) setSearchQuery("");
                        setInput(item ? item : "");
                        setSearchQuery(item ? item : "");
                        if (setCurrentPage) {
                          setCurrentPage(1);
                        }

                        setIsComponentVisible(!isComponentVisible);
                      }}
                    >
                      {item}
                    </ListGroup.Item>
                  ))}
                </>
              ) : (
                <ListGroup.Item>No data found</ListGroup.Item>
              )}
            </ListGroup>
          ) : null}
        </div>
      </div>
    </>
  );
}
