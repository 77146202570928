import React, { useCallback, useEffect, useState } from "react";
import { FloatingLabel, Form, Modal, Spinner } from "react-bootstrap";
import ManageIntakeServices from "../../Services/ManageIntakeServices";
import { toast } from "react-toastify";
import { format } from "date-fns";

const countryList = [
  "India",
  "Canada",
  "Australia",
  "USA",
  "UK",
  "New Zealand",
  "Ireland",
];

const StatusLabels = {
  "-1": "Deleted",
  0: "Inactive",
  1: "Active",
  2: "Yet to open",
  3: "Open",
  4: "Waitlist",
};

const ManageIntakeModal = ({ showModal, handleCloseModal, clgData }) => {
  const [tableData, setTableData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isDataEdited, setIsDataEdited] = useState(false);

  const [showResiDenceDropdown, setShowResidenceDropdown] = useState();
  const [showNationalityDropdown, setShowNationalityDropdown] = useState();

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectStatus, setSelectStatus] = useState();

  const getTableData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await ManageIntakeServices.getIntakes(clgData?.id);
      if (response.data.success === true) {
        setTableData(response.data.response);
        // toast.success(response.data.message);
      } else if (response.data.validationErrors) {
        response.data.validationErrors.forEach((verr) => toast.error(verr));
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString()
      );
    } finally {
      setIsLoading(false);
    }
  }, [clgData?.id]);

  useEffect(() => {
    getTableData();
  }, [getTableData]);

  const getStatusLabel = (statusId) => {
    return StatusLabels[statusId] || "Unknown";
  };

  const getStatusIdByLabel = (label) => {
    const entry = Object.entries(StatusLabels).find(
      ([_, value]) => value === label
    );
    return entry ? parseInt(entry[0]) : -1; // Return -1 if label not found
  };

  const handleResidenceClick = (index, item) => {
    setIsDataEdited(true);
    const updatedRowData = [...tableData];

    const rowSelection = updatedRowData[index].currentResidence || [];

    if (rowSelection.includes(item)) {
      updatedRowData[index].currentResidence = rowSelection.filter(
        (selectedItem) => selectedItem !== item
      );
    } else {
      updatedRowData[index].currentResidence = [...rowSelection, item];
    }

    setTableData(updatedRowData);
  };

  const handleNationalityClick = (index, item) => {
    setIsDataEdited(true);
    const updatedRowData = [...tableData];

    const rowSelection = updatedRowData[index].nationality || [];

    if (rowSelection.includes(item)) {
      updatedRowData[index].nationality = rowSelection.filter(
        (selectedItem) => selectedItem !== item
      );
    } else {
      updatedRowData[index].nationality = [...rowSelection, item];
    }

    setTableData(updatedRowData);
  };

  const handleStatusChange = (status) => {
    if (!selectedRows.length) {
      setSelectStatus();
      return;
    }
    setSelectStatus(status);
    setIsDataEdited(true);
    setTableData((prev) => {
      return prev.map((row, index) => {
        if (selectedRows.includes(index)) {
          return { ...row, statusId: getStatusIdByLabel(status) };
        }
        return row;
      });
    });
  };

  const handleSaveIntake = useCallback(async () => {
    try {
      const body = selectedRows.map((row) => tableData?.[row]);
      const response = await ManageIntakeServices.updateIntakes(tableData);
      if (response.data.success === true) {
        toast.success(response.data.message);
        handleCloseModal();
      } else if (response.data.validationErrors) {
        response.data.validationErrors.forEach((verr) => toast.error(verr));
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString()
      );
    }
  }, [handleCloseModal, selectedRows, tableData]);

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      centered
      aria-labelledby="contained-modal-title-vcenter"
      size="xl"
      className="custom-modal"
    >
      <Modal.Body className="p-5">
        <i
          className="bi bi-x-circle-fill text2 p-3 text-danger position-absolute end-0 top-0"
          style={{ cursor: "pointer" }}
          onClick={handleCloseModal}
        ></i>
        <div className="row pt-5">
          <div className="col-12">
            <div className="table-responsive rounded-4 text-center">
              {isLoading ? (
                <Spinner />
              ) : (
                <>
                  <div className="d-flex justify-content-between mb-5">
                    <div className="d-flex bg-dark1 rounded-pill p-3 px-5 gap-5">
                      <div
                        className={`rounded-pill border-0 ${
                          selectStatus === "Yet to open"
                            ? "bg-light-blue px-4"
                            : "text-white"
                        }  p-2 text1-3`}
                        onClick={() => handleStatusChange("Yet to open")}
                        style={{ cursor: "pointer" }}
                      >
                        Yet to open
                      </div>
                      <div
                        className={`rounded-pill border-0 ${
                          selectStatus === "Open"
                            ? "bg-light-blue px-4"
                            : "text-white"
                        }  p-2 text1-3`}
                        onClick={() => handleStatusChange("Open")}
                        style={{ cursor: "pointer" }}
                      >
                        Open
                      </div>
                      <div
                        className={`rounded-pill border-0 ${
                          selectStatus === "Inactive"
                            ? "bg-light-blue px-4"
                            : "text-white"
                        }  p-2 text1-3`}
                        onClick={() => handleStatusChange("Inactive")}
                        style={{ cursor: "pointer" }}
                      >
                        Inactive
                      </div>
                      <div
                        className={`rounded-pill border-0 ${
                          selectStatus === "Waitlist"
                            ? "bg-light-blue px-4"
                            : "text-white"
                        }  p-2 text1-3`}
                        onClick={() => handleStatusChange("Waitlist")}
                        style={{ cursor: "pointer" }}
                      >
                        Waitlist
                      </div>
                    </div>

                    <button
                      className={`me-2 border-0 p-3 px-5 text1-4 fw-bold rounded-pill ${
                        isDataEdited ? "bg-dark1 text-white" : ""
                      }`}
                      onClick={handleSaveIntake}
                      disabled={!isDataEdited}
                    >
                      Save
                    </button>
                  </div>
                  <table className="table">
                    <thead className="border-bottom">
                      <tr className="py-3 text1-5">
                        <th scope="col">Programs</th>
                        <th scope="col">Campus</th>
                        <th scope="col">Intake</th>
                        <th scope="col">Current Residence</th>
                        <th scope="col">Nationality</th>
                        <th scope="col">Status</th>
                        <th scope="col">
                          Select
                          <input
                            className="ms-2"
                            type="checkbox"
                            onChange={(event) => {
                              const isChecked = event.target.checked;
                              setSelectedRows((prev) => {
                                if (isChecked) {
                                  return Array.from(
                                    { length: tableData?.length },
                                    (_, index) => index
                                  );
                                } else {
                                  return [];
                                }
                              });
                            }}
                          />
                        </th>
                      </tr>
                    </thead>
                    <div className="mb-5"></div>
                    {tableData?.length ? (
                      <tbody className="text1-4">
                        {tableData?.map((data, index) => (
                          <tr className="py-3" key={index}>
                            <td>{data.collegeProgramName}</td>
                            <td>{data.collegeCampusName}</td>
                            <td>{format(data.date, "dd-MM-yyyy")}</td>
                            <td>
                              <FloatingLabel
                                controlId="floatingInput"
                                label="Residence"
                                onMouseLeave={() => setShowResidenceDropdown()}
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Residence"
                                  value={data?.currentResidence?.join(",")}
                                  onClick={() =>
                                    setShowResidenceDropdown(index)
                                  }
                                  style={{ cursor: "pointer", width: "150px" }}
                                />
                                <i
                                  className="bi bi-chevron-down"
                                  onClick={() =>
                                    setShowResidenceDropdown(index)
                                  }
                                  style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "60%",
                                    transform: "translateY(-50%)",
                                    cursor: "pointer",
                                  }}
                                ></i>

                                {showResiDenceDropdown === index ? (
                                  <ul
                                    class="list-group bg-light ps-0 z-3 position-absolute shadow bg-white rounded border-dark"
                                    style={{ width: "100%" }}
                                  >
                                    {countryList?.map((item) => (
                                      <li
                                        class={`list-group-item campus-item text-start text1-3  ${
                                          data?.currentResidence?.includes(item)
                                            ? "bg-light"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          handleResidenceClick(index, item)
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        {data?.currentResidence?.includes(
                                          item
                                        ) ? (
                                          <i class="bi bi-check-circle-fill me-2"></i>
                                        ) : (
                                          <i class="bi bi-circle me-2"></i>
                                        )}
                                        {item}
                                      </li>
                                    ))}
                                  </ul>
                                ) : null}
                              </FloatingLabel>
                            </td>
                            <td>
                              <FloatingLabel
                                controlId="floatingInput"
                                label="Nationality"
                                onMouseLeave={() =>
                                  setShowNationalityDropdown()
                                }
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Nationality"
                                  value={data?.nationality?.join(",")}
                                  onClick={() =>
                                    setShowNationalityDropdown(index)
                                  }
                                  style={{ cursor: "pointer", width: "150px" }}
                                />
                                <i
                                  className="bi bi-chevron-down"
                                  onClick={() =>
                                    setShowNationalityDropdown(index)
                                  }
                                  style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "60%",
                                    transform: "translateY(-50%)",
                                    cursor: "pointer",
                                  }}
                                ></i>
                                {showNationalityDropdown === index ? (
                                  <ul
                                    className="list-group bg-light ps-0 z-3 position-absolute shadow bg-white rounded border-dark"
                                    style={{ width: "100%" }}
                                  >
                                    {countryList?.map((item, idx) => (
                                      <li
                                        key={idx}
                                        className={`list-group-item campus-item text-start text1-3  ${
                                          data?.nationality?.includes(item)
                                            ? "bg-light"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          handleNationalityClick(index, item)
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        {data?.nationality?.includes(item) ? (
                                          <i class="bi bi-check-circle-fill me-2"></i>
                                        ) : (
                                          <i class="bi bi-circle me-2"></i>
                                        )}
                                        {item}
                                      </li>
                                    ))}
                                  </ul>
                                ) : null}
                              </FloatingLabel>
                            </td>
                            <td style={{ width: "100px" }}>
                              {getStatusLabel(data.statusId)}
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedRows.includes(index)}
                                onChange={() =>
                                  setSelectedRows((prev) => {
                                    if (prev.includes(index)) {
                                      return prev.filter(
                                        (item) => item !== index
                                      );
                                    } else {
                                      return [...prev, index];
                                    }
                                  })
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr className="w-100 ">
                          <td
                            className="text1-8 text-dark1"
                            align="center"
                            colSpan={12}
                          >
                            No Data Found
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ManageIntakeModal;
