import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/logo_text_dark.png";
import headerBG from "../../assets/headerBG.png";
import LoginModal from "../../layout/LoginModal";
import "./Navbar.css";

export default function Navbar({
  isAuth,
  RolesService,
  getRole,
  isScroll = false,
}) {
  const [scrolled, setScrolled] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const { BranchHead, SuperAdmin } = RolesService;
  const menuRef = useRef(null);

  const handleScroll = () => {
    if (window.scrollY > 80) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    if (isScroll) {
      setScrolled(true);
      return;
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setCollapsed(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  const handleClose = () => {
    setCollapsed(false);
  };

  return (
    <div className={`mainNav d-print-none`}>
      <div className="position-relative">
        <img
          src={headerBG}
          className={`position-absolute img-fluid z-1`}
          alt="headerBG"
          style={
            scrolled
              ? {
                  display: "none",
                  transition: "display 0.4s ease",
                }
              : {
                  maxHeight: "210px",
                  minWidth: "100%",
                  transition: "minWidth 0.4s ease, opacity 0.4s ease",
                  opacity: "0.15",
                }
          }
        />
      </div>
      <nav
        className={`navbar navbar-expand-sm fixed-top ${
          scrolled ? "border bg-white" : ""
        }`}
        style={
          scrolled
            ? {
                paddingTop: "0px",
                width: "100vw",
                transition: "padding 0.4s ease",
              }
            : {
                paddingTop: "24px",
                width: "100vw",
                transition: "padding 0.4s ease",
              }
        }
      >
        <div className="container-fluid mx-md-5 px-md-5">
          <a className="navbar-brand ms-md-5" href="/">
            <img src={logo} className="img-fluid main-logo" alt="logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            aria-controls="navbarSupportedContent"
            aria-expanded={collapsed ? "true" : "false"}
            aria-label="Toggle navigation"
            onClick={handleToggle}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${collapsed ? "show" : ""} `}
            id="navbarSupportedContent"
            ref={menuRef}
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 mt-0 mt-lg-0 nav text-uppercase d-flex align-items-center gap-md-2 gap-3">
              <li className="nav-item d-sm-none">
                <i
                  onClick={handleClose}
                  style={{ cursor: "pointer" }}
                  className="bi bi-x-circle-fill text-dark1 text2-5 pt-0 nav-link pb-0 pe-2"
                ></i>
              </li>
              <li className="nav-item me-md-4">
                <NavLink to={`/`} className="nav-link text-dark1 p-2 p-md-3">
                  Home
                </NavLink>
              </li>
              <li className="nav-item me-md-4">
                <NavLink
                  to={`/agent`}
                  className="nav-link text-dark1 p-2 p-md-3"
                >
                  {!isAuth ? "Partner" : "Agent"}
                </NavLink>
              </li>
              <li className="nav-item me-md-4">
                <NavLink
                  to={`/blogs`}
                  className="nav-link text-dark1 p-2 p-md-3"
                >
                  Blogs
                </NavLink>
              </li>
              <li className="nav-item me-md-4">
                <NavLink
                  to={`/institutions`}
                  className="nav-link text-dark1 p-2 p-md-3"
                >
                  Institutions
                </NavLink>
              </li>
              <>
                {isAuth ? (
                  <li className="nav-item me-md-4">
                    <NavLink
                      to={`/user${
                        getRole === SuperAdmin || getRole === BranchHead
                          ? "/dashboard"
                          : ""
                      }`}
                      className="nav-link text-dark1"
                    >
                      Dashboard
                    </NavLink>
                  </li>
                ) : (
                  <button
                    type="button"
                    className={`btn btn-dark-blue rounded-pill px-md-5 py-sm-3 p-3 py-2 me-sm-5 text1-3 ${
                      collapsed ? "ms-4 d-flex justify-content-end" : ""
                    }`}
                    onClick={() => setModalShow(true)}
                  >
                    Partner Login
                  </button>
                )}
              </>
            </ul>
          </div>
        </div>
      </nav>
      {modalShow ? (
        <LoginModal modalShow={modalShow} setModalShow={setModalShow} />
      ) : null}
    </div>
  );
}
