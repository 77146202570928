import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

import CopyToClipboardButton from "../../components/CopyToClipboardButton";
import Slider from "react-slick";

export default function BlogViewMobile({
  blogData,
  setcurrentPage,
  currentPage,
  Blogs,
  totalPages,
}) {
  const currentUrl = window.location.href;

  const FormatDate = ({ date }) => {
    const formatDate = (datetime) => {
      const dateObj = new Date(datetime);
      const day = String(dateObj.getDate()).padStart(2, "0");
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const month = monthNames[dateObj.getMonth()];
      const year = String(dateObj.getFullYear());
      return `${day} ${month} ${year}`;
    };

    return <>{formatDate(date)}</>;
  };
  const carouselSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    waitForAnimate: false,
    autoplay: true,
    pauseOnFocus: true,
    pauseOnHover: true,
    speed: 500,
    centerMode: true,
    centerPadding: "0px",
    autoplaySpeed: 2800,
    arrows: false,
    adaptiveHeight: true,
  };

  return (
    <div
      className="d-block d-sm-none container"
      style={{ paddingTop: "120px" }}
    >
      <div className="row justify-content-center">
        <div className="col-12 px-4">
          <div className=" w-100">
            <img
              src={blogData.image}
              className="img-fluid w-100  rounded-4"
              alt="blog pic"
              style={{ height: "260px" }}
            />

            <div className="d-flex justify-content-between align-items-center px-2">
              <div className="my-5 d-flex flex-column gap-3">
                <div className="text1-5 text-dark2">
                  <FormatDate date={blogData.createdDate} />
                </div>
                <h2>{blogData.title}</h2>
              </div>
              <div className="">
                <div className="text1-4">
                  <div className="d-flex justify-content-end align-items-center ">
                    <div className="btn-group dropstart">
                      <div
                        className="text-tick-blue pt-3"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          width: "48px",
                          height: "48px",
                          cursor: "pointer",
                        }}
                      >
                        <i className="bi bi-share-fill fs-2 d-flex justify-content-center align-items-center" />
                      </div>
                      <ul className="dropdown-menu border-0 p-2 rounded-pill bg-white shadow">
                        <div className="d-flex">
                          <CopyToClipboardButton textToCopy={currentUrl} />
                          <EmailShareButton
                            url={currentUrl}
                            subject={blogData.title}
                            body={`Created on ${blogData.date} by Education Planner`}
                            className="me-1"
                          >
                            <EmailIcon size={36} round={true} />
                          </EmailShareButton>
                          <FacebookShareButton
                            url={currentUrl}
                            subject={blogData.title}
                            body={`Created on ${blogData.date} by Education Planner`}
                            className="me-1"
                          >
                            <FacebookIcon size={36} round={true} />
                          </FacebookShareButton>
                          <LinkedinShareButton
                            url={currentUrl}
                            subject={blogData.title}
                            body={`Created on ${blogData.date} by Education Planner`}
                            className="me-1"
                          >
                            <LinkedinIcon size={36} round={true} />
                          </LinkedinShareButton>
                          <TwitterShareButton
                            url={currentUrl}
                            subject={blogData.title}
                            body={`Created on ${blogData.date} by Education Planner`}
                            className="me-1"
                          >
                            <TwitterIcon size={36} round={true} />
                          </TwitterShareButton>
                          <WhatsappShareButton
                            url={currentUrl}
                            subject={blogData.title}
                            body={`Created on ${blogData.date} by Education Planner`}
                            className="me-1"
                          >
                            <WhatsappIcon size={36} round={true} />
                          </WhatsappShareButton>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 px-5">
          <div className=" fs-3 mt-3 text-break">{blogData.description}</div>
        </div>
        <div className="col-7 mt-4">
          <hr className="custom-hr opacity-100" />
        </div>
      </div>

      <div className="slider-container carousel  py-5">
        <Slider {...carouselSettings}>
          {Blogs.map((blog, index) => (
            <div className="card blog-container" key={index}>
              <div className="row g-0 align-items-center border-bottom border-3 border-primary rounded-2">
                <div className="col-md-4 p-2">
                  <img
                    src={blog.image}
                    className="img-fluid rounded shadow w-100"
                    alt="blog pics"
                    style={{ height: "100px" }}
                  />
                </div>
                <div className="col-md-8 ">
                  <div className="card-body blog-carousel">
                    <div className="card-title text1-3 fw-bold m-0">
                      {blog.title}
                    </div>

                    <div className="card-text text1-1 d-flex justify-content-between mt-2">
                      <div className="text-break text-body-secondary me-1">
                        Posted On: <FormatDate date={blog.createdDate} />
                      </div>
                    </div>
                    <div>
                      <a
                        className="text-dark link-offset-2 link-offset-3-hover link-underline text1-2"
                        href={`/#/blogView/${blog.id}`}
                      >
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}
