import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import TokenService from "../../Services/TokenService";
export default function CheckoutForm({
  studentCurrency,
  collegeCurrency,
  exchangeData,
  currentApplication,
  setShow,
  PaymentService,
  getPaymentDetails,
  selectedPromoCode,
  collegeDetailsApplicationFee,
  setPaymentDoneShow,
}) {
  const getUser = TokenService.getUser();
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [isStripeLoading, setIsStripeLoading] = useState(true);

  useEffect(() => {
    if (elements) {
      const element = elements.getElement("payment");
      element.on("ready", () => {
        setIsStripeLoading(false);
      });
    }
  }, [elements]);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe
      .retrievePaymentIntent(clientSecret)
      .then(async ({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case "succeeded":
            setMessage("Payment succeeded!");
            break;
          case "processing":
            setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            setMessage("Your payment was not successful, please try again.");
            break;
          default:
            setMessage("Something went wrong.");
            break;
        }
      });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {},
      redirect: "if_required",
    });
    const paydata = paymentIntent ? paymentIntent : error.payment_intent;

    if (error) {
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
        toast.error(error.message);
      } else {
        setMessage(
          error.message ? error.message : "An unexpected error occurred."
        );
        toast.error(
          error.message ? error.message : "An unexpected error occurred."
        );
      }
      setIsLoading(false);
    }

    const nameParts = paydata.shipping.name.split(" ");
    const firstName = nameParts[0];
    const lastName = nameParts.slice(1).join(" ");

    var data = {
      walletAmount: 0,
      successed: paymentIntent ? true : false,
      paymentStatus: paydata.status,
      studentId: currentApplication.studentId,
      studentApplicationId: currentApplication.id,
      totalPayableAmount: exchangeData.baseAmount,
      transactionId: paydata.id,
      responseText: paydata.status,
      remarks: paymentIntent ? "Pay Success" : "Pay failed",
      statusId: 1,
      applicationFee: collegeDetailsApplicationFee,
      promoCodeAmount: !selectedPromoCode.value
        ? 0
        : (collegeDetailsApplicationFee * selectedPromoCode.value) / 100,
      transactionChargesAmount: !selectedPromoCode.value
        ? (collegeDetailsApplicationFee * 0) / 100
        : ((collegeDetailsApplicationFee -
            (collegeDetailsApplicationFee * selectedPromoCode.value) / 100) *
            0) /
          100,
      currencyConversionRate: exchangeData.exchangeRate,
      cardNumber: "424242424242",
      expMonth: "12",
      expYear: "24",
      addressCity: paydata.shipping.address.city,
      addressCountry: paydata.shipping.address.country,
      billingAddress: paydata.shipping.address.line1,
      billingState: paydata.shipping.address.state,
      billingZip: paydata.shipping.address.postal_code,
      cvv: "123",
      firstName: firstName,
      lastName: lastName,
      promoCodeName: selectedPromoCode.name,
      companyId: getUser.companyId,
      mode: "Card",
      currency: studentCurrency.currencyShortName,
    };

    if (paydata.status === "succeeded") {
      try {
        const response = await PaymentService.create(data);

        if (response.data.success === true) {
          paymentIntent && getPaymentDetails();
          //getStudent();

          setShow(false);
          setPaymentDoneShow(true);
          toast.success(response.data.message);
        } else if (
          response.data.success === false &&
          response.data.validationErrors === null
        ) {
          toast.info("Try again");
        } else if (response.data.validationErrors) {
          response.data.validationErrors.map((verr) => toast.error(verr));
        } else {
          toast.error("Something Went Wrong saving in db");
        }
      } catch (e) {
        toast.error(e);
      } finally {
        setIsLoading(false);
      }

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.

      setIsLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={`w-100 p-5`}
      style={{ minWidth: "420px" }}
    >
      {/* <BillingDetails isLoading={isLoading} currentApplication={currentApplication} /> */}
      {/* <div className="text1-6 my-1 text-center border-bottom ">Payment Details</div> */}
      <LinkAuthenticationElement />
      <PaymentElement
        options={{
          layout: "tabs",
        }}
      />
      {isStripeLoading && (
        <>
          {/* <div className="d-flex justify-content-center align-items-center p-2 text1-6 text-normal-red">
            Setting up payment...
            <div className="spinner-grow" style={{ width: "36px", height: "36px" }} role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div> */}
        </>
      )}
      {!isStripeLoading && (
        <div className="d-flex justify-content-center">
          <button
            disabled={isLoading || !stripe || !elements}
            id="submit"
            className={`${
              isLoading ? "bg-dark1" : "gradiantButton"
            }  rounded-pill py-3 font-bold text1-6 w-50 d-flex justify-content-center align-items-center mt-3`}
          >
            <span id="button-text">
              {isLoading || !stripe || !elements ? (
                <span className="spinner-border"></span>
              ) : (
                <span className="font-bold text1-6 text-white">
                  Pay {studentCurrency?.currencyShortName}{" "}
                  {exchangeData.convertedAmount}
                </span>
              )}
            </span>
          </button>
          {/* Show any error or success messages */}
          <div className="text-center text1-4 mt-3 d-none">
            &#x20B9;{exchangeData.convertedAmount} (1 CAD = &#x20B9;
            {exchangeData.exchangeRate})
          </div>
        </div>
      )}

      {message && <div className="text-center text1-4 mt-3">{message}</div>}
    </form>
  );
}
