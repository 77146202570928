import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import ProgramSearchService from "../../../Services/ProgramSearchService";
import { toast } from "react-toastify";
import FormatIntkeDate from "../../../components/UI/FormatIntkeDate";
import "./AdvanceFilterHomePage.css";

const requirementList = [
  "IELTS",
  "TOEFL",
  "PTE",
  "Duolingo",
  "GMAT",
  "GRE",
  "SAT",
];

const countryList = [
  "India",
  "Canada",
  "Australia",
  "USA",
  "UK",
  "New Zealand",
  "Ireland",
];

export default function AdvanceFilterHomePage({
  applySelectedFilters,
  setApplySelectedFilters,
  appliedDurationAndFee,
  setAppliedDurationAndFee,
  selectedOptions,
  setSelectedOptions,
  checkedOptions,
  setCheckedOptions,
  isSticky,
  searchResults,
  setCurrentPage,
  formatSessionIntakeDate
}) {
  const [advanceDataList, setAdvanceDataList] = useState([]);

  const getAdvanceSearchFilterData = async () => {
    await ProgramSearchService.advanceFilterList()
      .then((response) => {
        setAdvanceDataList(response.data);
      })
      .catch((e) => {
        toast.error(e);
      });
  };
  useEffect(() => {
    getAdvanceSearchFilterData();
  }, [searchResults]);

  const collegeNameToFilter = searchResults?.map((item) => item.collegeName);
  const campusToFilter = searchResults?.map((item) => item.campus)?.flat();
  const credentialsToFilter = searchResults?.map((item) => item.credential);
  const provinceToFilter = searchResults?.map((item) => item.province);
  const intakeToFilter = searchResults?.map((intakes) =>
    intakes.intakeList?.map((item) => item.date)
  );

  const areasofInterestToFilter = searchResults?.map(
    (item) => item.areasofInterest
  );

  const isNotEmpty = (value) => {
    return (
      value !== null &&
      value !== undefined &&
      (Array.isArray(value) ? value.length > 0 : value !== "")
    );
  };

  const ieltsRequirementToFilter = searchResults?.some((item) =>
    isNotEmpty(item.ieltsRequirement)
  );
  const pteRequirementToFilter = searchResults?.some((item) =>
    isNotEmpty(item.pteRequirement)
  );
  const toeflRequirementToFilter = searchResults?.some((item) =>
    isNotEmpty(item.toeflRequirement)
  );
  const duolingoRequirementToFilter = searchResults?.some((item) =>
    isNotEmpty(item.duolingoRequirement)
  );
  const gmatToFilter = searchResults?.some((item) => isNotEmpty(item.gmat));
  const greToFilter = searchResults?.some((item) => isNotEmpty(item.gre));
  const satToFilter = searchResults?.some((item) => isNotEmpty(item.sat));

  const conditions = [
    ieltsRequirementToFilter,
    toeflRequirementToFilter,
    pteRequirementToFilter,
    duolingoRequirementToFilter,
    gmatToFilter,
    greToFilter,
    satToFilter,
  ];
  const filteredRequirementList = requirementList.filter(
    (_, index) => conditions[index]
  );

  const uniqueIntakeDates = advanceDataList?.intakeLst?.reduce(
    (acc, intake) => {
      const date = new Date(intake.date);
      const year = date.getFullYear();
      const month = date.getMonth();

      const existing = acc.find((item) => {
        const existingDate = new Date(item);
        return (
          existingDate.getFullYear() === year &&
          existingDate.getMonth() === month
        );
      });

      if (!existing) {
        acc.push(intake.date); // unqiue dates
      }
      return acc;
    },
    []
  );

  const seasionIntakes = advanceDataList?.intakeLst?.map((item) => {
    return item
  })

  const filterList = [
    {
      tabTitle: "College Name",
      optionsList: advanceDataList.collegeNameLst?.filter((college) =>
        collegeNameToFilter?.includes(college)
      ),
    },
    {
      tabTitle: "Campus",
      optionsList: advanceDataList.campusLst?.filter((campus) =>
        campusToFilter?.includes(campus)
      ),
    },
    {
      tabTitle: "Program Type",
      optionsList: advanceDataList.programTypeLst?.filter((credential) =>
        credentialsToFilter?.includes(credential)
      ),
    },
    {
      tabTitle: "Province",
      optionsList: advanceDataList.provinceLst?.filter((province) =>
        provinceToFilter?.includes(province)
      ),
    },
    {
      tabTitle: "Intake",
      optionsList: seasionIntakes, // advanceDataList.intakeLst?.filter((intake) => intakeToFilter.includes(intake.date)).map((intake) => intake.date),
    },
    {
      tabTitle: "English Requirement",
      optionsList: filteredRequirementList,
    },
    {
      tabTitle: "Areas of Interest",
      optionsList: advanceDataList.areasofInterestLst?.filter(
        (areasofInterest) => areasofInterestToFilter?.includes(areasofInterest)
      ),
    },
    {
      tabTitle: "Current residence",
      optionsList: countryList,
    },
    {
      tabTitle: "Nationality",
      optionsList: countryList
    },

  ];
  const handleDurationAndFee = (e) => {
    const { name, value } = e.target;
    setAppliedDurationAndFee((prevData) => ({ ...prevData, [name]: value }));
  };

  const durationNumberOptions = Array.from(
    { length: 12 },
    (_, index) => index + 1
  );
  const handleCheckboxClick = (tabTitle, option) => {
    // Check if the option is already checked
    const isChecked = checkedOptions[tabTitle]?.includes(option);

    if (isChecked) {
      // If the option is already checked, remove it from both selectedOptions and checkedOptions
      setSelectedOptions((prevSelectedOptions) => {
        const updatedOptions = { ...prevSelectedOptions };
        if (updatedOptions[tabTitle]) {
          updatedOptions[tabTitle] = updatedOptions[tabTitle].filter(
            (item) => item !== option
          );
        }
        return updatedOptions;
      });

      setCheckedOptions((prevCheckedOptions) => {
        const updatedOptions = { ...prevCheckedOptions };
        if (updatedOptions[tabTitle]) {
          updatedOptions[tabTitle] = updatedOptions[tabTitle].filter(
            (item) => item !== option
          );
        }
        return updatedOptions;
      });
    } else {
      // If the option is not checked, add it to both selectedOptions and checkedOptions
      setSelectedOptions((prevSelectedOptions) => {
        const updatedOptions = { ...prevSelectedOptions };
        if (updatedOptions[tabTitle]) {
          updatedOptions[tabTitle].push(option);
        } else {
          updatedOptions[tabTitle] = [option];
        }
        return updatedOptions;
      });

      setCheckedOptions((prevCheckedOptions) => {
        const updatedOptions = { ...prevCheckedOptions };
        if (updatedOptions[tabTitle]) {
          updatedOptions[tabTitle].push(option);
        } else {
          updatedOptions[tabTitle] = [option];
        }
        return updatedOptions;
      });
    }
  };

  const [isFilterApplied, setIsFilterApplied] = useState(false); // State to track if filters are applied

  useEffect(() => {
    // Check if appliedDurationAndFee has any non-empty values
    const isDurationAndFeeNotEmpty = Object.values(appliedDurationAndFee).some(
      (value) => value !== ""
    );

    // Enable the "Apply Filter" button if appliedDurationAndFee is not empty or any other filters are selected
    setIsFilterApplied(
      isDurationAndFeeNotEmpty || Object.keys(selectedOptions).length > 0
    );
  }, [appliedDurationAndFee, selectedOptions]);

  const handleClearAllFilters = () => {
    setCheckedOptions([]);
    setSelectedOptions({});
    setAppliedDurationAndFee({
      durationNumber: "",
      durationPeriod: "",
      applicationFee: -1,
      tuitionFee: -1,
    });
    setApplySelectedFilters("");
    setAppliedDurationAndFee({
      durationNumber: "",
      durationPeriod: "",
      applicationFee: -1,
      tuitionFee: -1,
    });
  };
  const handleApplyFilters = () => {
    filterList.forEach((tab, index) => {
      if (checkedOptions[index]) {
        selectedOptions[tab.tabTitle] = checkedOptions[index].map(
          (optionIndex) => tab.optionsList[optionIndex]
        );
      }
    });

    setApplySelectedFilters(selectedOptions);
    setAppliedDurationAndFee(appliedDurationAndFee);
  };

  useEffect(() => {
    setCurrentPage(1);
    setApplySelectedFilters(selectedOptions);
    setAppliedDurationAndFee(appliedDurationAndFee);
  }, [selectedOptions, appliedDurationAndFee]);
  // const removeSelectedOption = (tabTitle, option) => {
  //   setSelectedOptions((prevSelectedOptions) => {
  //     const updatedOptions = { ...prevSelectedOptions };

  //     if (updatedOptions[tabTitle]) {
  //       updatedOptions[tabTitle] = updatedOptions[tabTitle].filter((item) => item !== option);

  //       if (updatedOptions[tabTitle].length === 0) {
  //         delete updatedOptions[tabTitle];
  //       }
  //     }

  //     return updatedOptions;
  //   });
  // };

  // function getMonthsBetweenDates(startDate, endDate) {
  //   const months = [];
  //   let currentDate = new Date(startDate);

  //   while (currentDate <= endDate) {
  //     const month = currentDate.toLocaleString('en-US', { month: 'short' });
  //     const year = currentDate.getFullYear();
  //     months.push(`${month} ${year}`);
  //     currentDate.setMonth(currentDate.getMonth() + 1);
  //   }

  //   return months;
  // }

  // // start date and end date:
  // const startDate = new Date('2023-06-01');
  // const endDate = new Date('2024-02-01');
  // const monthsBetween = getMonthsBetweenDates(startDate, endDate);

  // console.log(monthsBetween);

  return (
    <>
      {/* {Object.entries(selectedOptions).map(([tabTitle, selectedValues]) => (
        <div key={tabTitle}>
          <h3>{tabTitle}</h3>
          <ul>
            {selectedValues.map((option) => (
              <li key={option}>
                {option}
                <button onClick={() => removeSelectedOption(tabTitle, option)}>Remove</button>
              </li>
            ))}
          </ul>
        </div>
      ))} */}
      <div
        className={`d-${Object.keys(applySelectedFilters).length === 0
          ? "none"
          : isSticky
            ? "flex"
            : "none"
          } justify-content-start align-items-center mb-2`}
      >
        <button
          className="btn btn-sm btn-warning text1-3 rounded-pill p-2"
          onClick={handleClearAllFilters}
          disabled={!isFilterApplied}
        >
          Clear All
        </button>
        {/* <button
          className="btn btn-sm btn-outline-success text1-3 rounded-pill p-2"
          disabled={!isFilterApplied}
          onClick={() => handleApplyFilters(selectedOptions)}
        >
          Apply Filter
        </button> */}
      </div>
      <div className="filters-wrapper">
        <Accordion>
          <Accordion.Item eventKey={77}>
            <p className="accordian-header">Filters</p>
          </Accordion.Item>
          <Accordion.Item eventKey={77}>
            <Accordion.Header>
              <span className="accordian-item-title">{"Duration & Fee"}</span>
            </Accordion.Header>
            <Accordion.Body>
              <div className="text1-3">
                <div className="row justify-content-center">
                  <div className="col-12 text-center filter-subheading">
                    Select Duration
                  </div>
                  <div className="col-5">
                    <select
                      className="form-select rounded-pill border py-2 text1-3"
                      aria-label="Select Year"
                      required
                      name="durationNumber"
                      value={appliedDurationAndFee.durationNumber}
                      onChange={handleDurationAndFee}
                    >
                      <option disabled>Number</option>
                      <option value="">All</option>
                      {durationNumberOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-5">
                    <select
                      className="form-select rounded-pill border py-2 text1-3"
                      aria-label="Select Year"
                      required
                      name="durationPeriod"
                      value={appliedDurationAndFee.durationPeriod}
                      onChange={handleDurationAndFee}
                    >
                      <option disabled>Period</option>
                      <option value="">All</option>
                      <option value="Year">Year</option>
                      <option value="Month">Month</option>
                    </select>
                  </div>
                </div>

                <div className="col-12 pt-4">
                  <div className="text-center filter-subheading">
                    Application Fee
                  </div>
                  <input
                    type="text"
                    className="form-control rounded-pill text1-3 range-input"
                    name="applicationFee"
                    placeholder="Enter application fee"
                    value={(parseFloat(appliedDurationAndFee.applicationFee) ===
                      -1.0
                      ? 0
                      : appliedDurationAndFee.applicationFee
                    ).toString()}
                    onChange={handleDurationAndFee}
                    pattern="[0-9]*"
                    title="Please enter digits only"
                    onFocus={(e) => (e.target.value = "")}
                  />

                  <input
                    type="range"
                    className="w-100 range-slider"
                    id="applicationFee"
                    name="applicationFee"
                    min={0} // Set the minimum value 0
                    max={
                      advanceDataList.highestCollegeApplicationFee
                        ? advanceDataList.highestCollegeApplicationFee
                        : 0
                    } // Set highies Fee Value
                    value={appliedDurationAndFee.applicationFee}
                    onChange={handleDurationAndFee}
                  />
                  <div className="d-flex justify-content-between">
                    <div>0</div>
                    <div>
                      {advanceDataList.highestCollegeApplicationFee
                        ? advanceDataList.highestCollegeApplicationFee / 2
                        : 0}
                    </div>
                    <div>
                      {advanceDataList.highestCollegeApplicationFee
                        ? advanceDataList.highestCollegeApplicationFee
                        : 0}
                    </div>
                  </div>
                </div>
                <div className="col-12 pt-4">
                  <div className="text-center filter-subheading">
                    Tution Fee
                  </div>
                  <input
                    type="text"
                    className="form-control rounded-pill text1-3 range-input"
                    name="tuitionFee"
                    placeholder="Enter tution fee"
                    value={(parseFloat(appliedDurationAndFee.tuitionFee) ===
                      -1.0
                      ? 0
                      : appliedDurationAndFee.tuitionFee
                    ).toString()}
                    onChange={handleDurationAndFee}
                    pattern="[0-9]*"
                    title="Please enter digits only"
                    onFocus={(e) => (e.target.value = "")}
                  />

                  <input
                    type="range"
                    className="w-100 range-slider"
                    id="tuitionFee"
                    name="tuitionFee"
                    min={0} // Set the minimum value 0
                    max={
                      advanceDataList.highestProgramTuitionFee
                        ? Math.ceil(advanceDataList.highestProgramTuitionFee)
                        : 0
                    } // Set highies Fee Value
                    value={appliedDurationAndFee.tuitionFee}
                    onChange={handleDurationAndFee}
                  />
                  <div className="d-flex justify-content-between">
                    <div>0</div>
                    <div>
                      {advanceDataList.highestProgramTuitionFee
                        ? Math.ceil(advanceDataList.highestProgramTuitionFee) /
                        2
                        : 0}
                    </div>
                    <div>
                      {advanceDataList.highestProgramTuitionFee
                        ? Math.ceil(advanceDataList.highestProgramTuitionFee)
                        : 0}
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          {filterList.map((filter, index) => (
            <Accordion.Item eventKey={index} key={index}>
              <Accordion.Header>
                <span className="accordian-item-title">{filter.tabTitle}</span>
              </Accordion.Header>
              <Accordion.Body>
                {filter.optionsList?.map((option, optionIndex) => (
                  <ul key={optionIndex} className="m-0 p-0 text1-3">
                    <li
                      className="py-3 d-flex justify-content-between align-items-center blue-checkbox"
                      onClick={() =>
                        handleCheckboxClick(filter.tabTitle, option)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <span className="pe-2">
                        {filter.tabTitle === "Areas of Interest" ? (
                          option?.replace(/\//g, ",")
                        ) : filter.tabTitle === "Intake" ? (
                          formatSessionIntakeDate(option)
                        ) : (
                          option
                        )}
                      </span>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id={`${filter.id}-option-${index}`}
                        checked={checkedOptions[filter.tabTitle]?.includes(
                          option
                        )}
                        //onChange={() => handleCheckboxClick(index, optionIndex)}
                        style={{
                          minWidth: "16px",
                          minHeight: "16px",
                          cursor: "pointer",
                        }}
                        readOnly
                      />
                    </li>
                  </ul>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </>
  );
}
