import React, { useEffect, useState, useCallback } from "react";
import UserPanel from "../UserPanel";
import wallet1 from "../../assets/icons/wallet1.svg";
import WalletDataService from "../../Services/WalletService";
import WalletPay from "./WalletPay";
import DashboardService from "../../Services/DashboardService";
import { toast } from "react-toastify";
import Pagination from "../../components/UI/Pagination";
import SelectItemsPerPage from "../../components/UI/SelectItemsPerPage";
import FormatDOB from "../../components/UI/FormatDOB";
import PageTitle from "../../components/PageTitle";
import RolesService from "../../Services/RolesService";
import TokenService from "../../Services/TokenService";
import AgentsDropdown from "./AgentsDropdown";
import AgentsDataService from "../../Services/AgentsService";
import { debounce } from "../../shared/helpers";
import FormatDate from "../../components/UI/FormatDate";

export default function Wallet() {
  const { SuperAdmin, Accounts } = RolesService;
  const getRole = TokenService.getUserRole();
  const [currentWalletBalance, setcurrentWalletBalance] = useState({
    totalWalletAmount: 0,
  });
  const [dropdownData, setDropdownData] = useState([]);
  const [inputData, setInputData] = useState("");
  const [selectedAgent, setSelectedAgent] = useState("");

  const retrieveDashbaord = async () => {
    try {
      const response = await DashboardService.dashboardByCompanyId();
      if (response.data.success === true) {
        setcurrentWalletBalance(response.data.response);
      }
    } catch (error) {
      toast.error("Error while fetching balance");
    }
  };
  const getAgentList = useCallback(async (searchQuery) => {
    try {
      const resp = await AgentsDataService.find({
        currentPage: 1,
        pageSize: 10000,
        search: searchQuery,
      });
      setDropdownData(resp.data.response);
    } catch (err) {
      toast.err(err.message);
    }
  }, []);

  const handleAgentDebouncedSearch = useCallback(
    debounce((search) => getAgentList(search), 400),
    []
  );
  const handleChange = useCallback(
    async (search) => {
      setInputData(search);

      handleAgentDebouncedSearch(search);
    },
    [handleAgentDebouncedSearch]
  );

  useEffect(() => {
    retrieveDashbaord();
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [mode, setMode] = useState(null);
  const [totalItems, setTotalItems] = useState(0);

  const getRequestParams = useCallback(
    (currentPage, itemsPerPage, mode, selectedAgent) => {
      let params = {};

      if (currentPage) {
        params["currentPage"] = currentPage;
      }

      if (itemsPerPage) {
        params["pageSize"] = itemsPerPage;
      }
      if (mode) {
        params["filter"] = mode;
      }
      if (selectedAgent) {
        params["companyId"] = selectedAgent;
      }

      return params;
    },
    []
  );

  const handleSelectMode = useCallback((event) => {
    if (event.target.value === "All") {
      setMode(null);
    } else {
      setMode(event.target.value);
    }
    setCurrentPage(1);
  }, []);

  const [walletData, setWalletData] = useState();
  const findWalletData = useCallback(() => {
    var params;
    if (getRole === SuperAdmin || getRole === Accounts) {
      params = getRequestParams(currentPage, itemsPerPage, mode, selectedAgent);
      WalletDataService.getAllWalletList(params).then((response) => {
        setWalletData(response.data.response);
        setTotalItems(response.data.response[0]?.totalItems);
      });
    } else {
      params = getRequestParams(currentPage, itemsPerPage, mode);
      WalletDataService.find(params).then((response) => {
        setWalletData(response.data.response);
        setTotalItems(response.data.response[0]?.totalItems);
      });
    }
  }, [
    Accounts,
    SuperAdmin,
    currentPage,
    getRequestParams,
    getRole,
    itemsPerPage,
    mode,
    selectedAgent,
  ]);

  const handlePageSizeChange = (event) => {
    event.preventDefault();
    setItemsPerPage(parseInt(event.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getAgentList();
  }, []);
  useEffect(() => {
    findWalletData();
  }, [findWalletData]);

  const floatValue = (value) => {
    return value.toFixed(2);
  };
  return (
    <>
      <PageTitle title="Wallet" />
      <div className="p-2 mt-2">
        <div className="row align-items-center">
          <div className="col">
            <UserPanel title={"Wallet"} />
          </div>
        </div>
        <div className="row">
          {getRole !== SuperAdmin && getRole !== Accounts ? (
            <div className="col-6 mb-3">
              <div className="bg-light-blue p-4 rounded-4">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="text2">
                    Current Balance
                    <div className="text3">
                      <strong>
                        ${floatValue(currentWalletBalance.totalWalletAmount)}
                      </strong>
                    </div>
                  </div>

                  <div>
                    <button
                      className="btn bg-dark1 d-flex justify-content-center align-items-center rounded-circle"
                      style={{ width: "80px", height: "80px" }}
                    >
                      <img src={wallet1} alt="wallet-1" />
                    </button>
                  </div>
                </div>
                <div className="mb-2 col-4">
                  <WalletPay
                    findWalletData={findWalletData}
                    retrieveDashbaord={retrieveDashbaord}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="col-12">
            <div>
              <div className="row align-items-baseline mt-3 mx-2 mb-1">
                <div className="col">
                  <div className="d-flex align-items-center justify-content-between text-dark1 column-gap-4">
                    <div className="d-flex gap-2">
                      {getRole === SuperAdmin || getRole === Accounts ? (
                        <div className="form-floating text-white position-relative">
                          <AgentsDropdown
                            list={dropdownData}
                            value={inputData}
                            handleInputChange={(e) =>
                              handleChange(e.target.value)
                            }
                            handleItemClick={(item) => {
                              console.log({ item });
                              if (!item) handleChange("");
                              setInputData(item ? item.name : "All Agents");
                              setSelectedAgent(item ? item.id : "");
                              setCurrentPage(1);
                            }}
                            inputClassnames={`align-self-end select-container text1-4 bg-dark1 text-white mt-3`}
                          />
                          <label
                            className="text-white text1-2"
                            htmlFor="floatingInput"
                          >
                            Agent
                          </label>
                        </div>
                      ) : null}

                      <div className="form-floating">
                        <select
                          className="payment-select select-container text1-4 bg-dark1 text-white mt-3"
                          aria-label="lead sort"
                          defaultValue={"All"}
                          onChange={handleSelectMode}
                          id="floatingSelect"
                        >
                          <option value="All">All</option>
                          <option value="Debit">Debit</option>
                          <option value="Credit">Credit</option>
                        </select>
                        <label
                          className="text-white text1-2"
                          for="floatingSelect"
                        >
                          Payment Mode
                        </label>
                      </div>
                    </div>
                    <div className="d-flex gap-2">
                      <SelectItemsPerPage
                        handlePageSizeChange={handlePageSizeChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3 mb-4">
                {totalItems > 0 && (
                  <Pagination
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    dataLength={totalItems}
                    itemsPerPage={itemsPerPage}
                  />
                )}
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive rounded-4 text-center">
                    <table className="table custom-table1">
                      <thead className="bg-dark1 text-white">
                        <tr className="py-3 text1-3">
                          <th scope="col">Transaction No</th>
                          {getRole === SuperAdmin || getRole === Accounts ? (
                            <th scope="col">Agent Name</th>
                          ) : null}
                          <th scope="col">Student Name</th>
                          <th scope="col">Application Id</th>
                          <th scope="col">Transaction Amount</th>
                          <th scope="col">Transaction Type</th>
                          <th scope="col">Transaction Date</th>
                          {getRole === SuperAdmin || getRole === Accounts ? (
                            <th scope="col">Balance</th>
                          ) : null}
                        </tr>
                      </thead>
                      {Boolean(walletData?.length) ? (
                        <tbody className="text1-4">
                          {walletData?.map((transaction) => (
                            <tr
                              key={transaction.id}
                              className={`border-bottom border-white ${
                                transaction.amount > 0 &&
                                "bg-success-subtle bg-opacity-25"
                              }  ${
                                transaction.amount < 0 &&
                                "bg-danger-subtle bg-opacity-25"
                              }`}
                            >
                              <td>{transaction.transactionNumber}</td>
                              {getRole === SuperAdmin ||
                              getRole === Accounts ? (
                                <td>{transaction.agentName}</td>
                              ) : null}
                              <td>
                                {transaction.studentName
                                  ? transaction.studentName
                                  : "N/A"}
                              </td>
                              <td>
                                {transaction.studentApplicationId ? (
                                  <FormatDate
                                    date={
                                      transaction.studentApplicationCreatedDate
                                    }
                                    idNo={
                                      transaction.studentApplicationIdNumber
                                    }
                                  />
                                ) : (
                                  "N/A"
                                )}
                              </td>
                              <td
                                className={`fw-bold ${
                                  transaction.amount > 0 && "text-success"
                                } ${transaction.amount < 0 && "text-danger"}`}
                              >
                                <span
                                  className={` ${
                                    transaction.type === "Credit" &&
                                    "text-success"
                                  } `}
                                >
                                  {transaction.amount > 0 && "+"}
                                </span>
                                {floatValue(transaction.amount)}
                              </td>
                              <td>{transaction.type}</td>
                              <td>
                                <FormatDOB date={transaction.createdDate} />
                              </td>
                              {getRole === SuperAdmin ||
                              getRole === Accounts ? (
                                <td>
                                  <span
                                    className={` ${
                                      transaction.type === "Credit" &&
                                      "text-success"
                                    } `}
                                  >
                                    ${floatValue(transaction?.balance)}
                                  </span>
                                </td>
                              ) : null}
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr className="w-100 ">
                            <td className="text1-8" align="center" colSpan={12}>
                              No Transactions Found
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                  {totalItems > 0 && (
                    <Pagination
                      currentPage={currentPage}
                      handlePageChange={handlePageChange}
                      dataLength={totalItems}
                      itemsPerPage={itemsPerPage}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
