import React from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import BlogDataService from "../../Services/BlogService";
import { toast } from "react-hot-toast";

export default function AddBlog({ pageTitle, getData }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [validated, setValidated] = useState(false);
  const initializeData = [{ title: "", description: "", image: "", statusId: 0 }];

  const [BlogData, setBlogData] = useState(initializeData);
  const [inputImage, setInputImage] = useState(undefined);
  const [IsSaving, setIsSaving] = useState(false);
  const handleInputImage = (event) => {
    const file = event.target.files && event.target.files[0]; // Check if files exist
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const fileContent = e.target.result;
        const base64String = btoa(fileContent);
        setInputImage(base64String);
      };
      reader.readAsBinaryString(file);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBlogData({ ...BlogData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;

    setValidated(true);

    if (form.checkValidity() === false) {
      event.stopPropagation();
      return; // Exit the function if the form is invalid
    }
    setIsSaving(true);
    var newData = {
      title: BlogData.title,
      link: BlogData.link,
      date: BlogData.date,
      description: BlogData.description,
      image: inputImage,
      statusId: BlogData.statusId,
    };

    try {
      // Display loading message
      const loadingMessage = toast.loading("Creating...");

      const response = await BlogDataService.create(newData);

      if (response.data.success === true) {
        getData();
        toast.success(response.data.message, { id: loadingMessage });
        setBlogData(initializeData);
        setInputImage(undefined);
        setValidated(false);
        setShow(false);
        setIsSaving(false);
      } else if (response.data.success === false && response.data.validationErrors.length > 0) {
        setIsSaving(false);
        response.data.validationErrors.map((verr) => toast.error(verr, { id: loadingMessage }));
      } else {
        setIsSaving(false);
        toast.error("Something Went Wrong", { id: loadingMessage });
      }
    } catch (error) {
      setIsSaving(false);
      console.error("An error occurred:", error);
      toast.error("An error occurred");
    }
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow} className="py-2 px-4 fs-5 rounded-pill">
        Add New {pageTitle}
      </Button>
      <Modal show={show} onHide={handleClose} className="mt-5" size="lg">
        <Modal.Header closeButton={!IsSaving}>
          <Modal.Title>Add {pageTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit} className="px-3">
            <Row xs={1} md={12} className="g-3">
              <FloatingLabel controlId="floatingInput" label="Title">
                <Form.Control type="text" placeholder="Title" required name="title" value={BlogData.title} onChange={handleInputChange} />
                <Form.Control.Feedback type="invalid">Please provide Title</Form.Control.Feedback>
              </FloatingLabel>

              <FloatingLabel controlId="floatingInput" label="Description">
                <Form.Control
                  as="textarea"
                  aria-label="Description"
                  required
                  name="description"
                  value={BlogData.description}
                  onChange={handleInputChange}
                  style={{ height: "100px" }}
                  className="fs-5"
                />
                <Form.Control.Feedback type="invalid">Please provide Description</Form.Control.Feedback>
              </FloatingLabel>

              <FloatingLabel controlId="floatingSelect" label="Select Status">
                <Form.Select
                  aria-label="Floating label select"
                  defaultValue={BlogData.statusId}
                  name="statusId"
                  onChange={handleInputChange}
                >
                  <option value={0}>Inactive</option>
                  <option value={1}>Active</option>
                </Form.Select>
              </FloatingLabel>

              <Form.Control type="file" required accept="image/jpeg, image/png" name="image" onChange={handleInputImage} />
              <Form.Control.Feedback type="invalid">Please select a jpg/jpeg or png file</Form.Control.Feedback>

              {inputImage === undefined ? (
                ""
              ) : (
                <div className="d-flex justify-content-center">
                  <Image src={`data:image/png;base64,${inputImage}`} rounded fluid style={{ width: "300px" }} />
                </div>
              )}
              {inputImage === undefined ? "" : <div className="text-center mt-2">Selected Image</div>}
              <Modal.Footer className="mt-4">
                <Button variant="secondary" onClick={handleClose} disabled={IsSaving}>
                  Close
                </Button>
                <Button type="submit" variant="success" disabled={IsSaving}>
                  save
                </Button>
              </Modal.Footer>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
