import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import { toast } from "react-hot-toast";
import BlogDataService from "../../Services/BlogService";

export default function EditBlog({ pageTitle, getData, data }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [BlogData, setBlogData] = useState(data);
  const [validated, setValidated] = useState(false);
  const [inputImage, setInputImage] = useState(undefined);
  const [IsSaving, setIsSaving] = useState(false);
  const [isNewImageSelected, setIsNewImageSelected] = useState(false);
  const handleInputImage = (event) => {
    const file = event.target.files && event.target.files[0]; // Check if files exist
    if (file) {
      setIsNewImageSelected(true);
      const reader = new FileReader();
      reader.onload = function (e) {
        const fileContent = e.target.result;
        const base64String = btoa(fileContent);
        setInputImage(base64String);
      };
      reader.readAsBinaryString(file);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBlogData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    setValidated(true);

    if (!form.checkValidity()) {
      event.stopPropagation();
      return;
    }
    setIsSaving(true);
    try {
      // Display loading message
      const loadingMessage = toast.loading("Updating...");
      if (isNewImageSelected) {
        var newData = {
          id: BlogData.id,
          title: BlogData.title,
          link: BlogData.link,
          date: BlogData.date,
          description: BlogData.description,
          image: inputImage,
          statusId: BlogData.statusId,
          isBlogImageUpdated: true,
        };
      } else {
        var newData = {
          id: BlogData.id,
          title: BlogData.title,
          link: BlogData.link,
          date: BlogData.date,
          image: null,
          description: BlogData.description,
          statusId: BlogData.statusId,
          isBlogImageUpdated: false,
        };
      }

      const response = await BlogDataService.update(newData);
      if (response.data.success === true) {
        getData();
        toast.success(response.data.message, { id: loadingMessage });
        setShow(false);
        setIsSaving(false);
        setInputImage(undefined);
      } else if (
        response.data.success === false &&
        response.data.response === false
      ) {
        toast.error(response.data.message, { id: loadingMessage });
        setIsSaving(false);
      } else if (
        response.data.success === false &&
        response.data.validationErrors.length > 0
      ) {
        console.log(errorMessage);
        const errorMessage = response.data.validationErrors
          .map((verr) => verr)
          .join(", ");
        toast.error(errorMessage, { id: loadingMessage });
        setIsSaving(false);
      } else {
        toast.error("Something Went Wrong", { id: loadingMessage });
        setIsSaving(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error("An error occurred");
      setIsSaving(false);
    }
  };

  return (
    <>
      <Button
        variant="outline-warning"
        onClick={handleShow}
        className="w-100 rounded-pill fs-5"
      >
        Edit
      </Button>
      <Modal show={show} onHide={handleClose} className="mt-5" size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit {pageTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            className="px-3"
          >
            <Row xs={1} md={12} className="g-3">
              <FloatingLabel controlId="floatingInput" label="Title">
                <Form.Control
                  type="text"
                  placeholder="Title"
                  required
                  name="title"
                  value={BlogData.title}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide Title
                </Form.Control.Feedback>
              </FloatingLabel>

              <FloatingLabel controlId="floatingInput" label="Description">
                <Form.Control
                  as="textarea"
                  aria-label="Description"
                  required
                  name="description"
                  value={BlogData.description}
                  onChange={handleInputChange}
                  style={{ height: "200px" }}
                  className="fs-5"
                />
                <Form.Control.Feedback type="invalid">
                  Please provide Description
                </Form.Control.Feedback>
              </FloatingLabel>

              <FloatingLabel controlId="floatingSelect" label="Select Status">
                <Form.Select
                  aria-label="Floating label select"
                  defaultValue={BlogData.statusId}
                  name="statusId"
                  onChange={handleInputChange}
                >
                  <option value={0}>Inactive</option>
                  <option value={1}>Active</option>
                </Form.Select>
              </FloatingLabel>

              <div className="d-flex justify-content-center">
                {BlogData.image && (
                  <>
                    <div className="me-5">
                      <div className="text-center">Current Image</div>
                      <Image
                        src={BlogData.image}
                        rounded
                        fluid
                        style={{ width: "220px", maxHeight: "140px" }}
                      />
                    </div>
                    <div className="ms-5">
                      <div className="text-center">
                        {inputImage
                          ? "New Selected Image"
                          : "No New Image Select"}
                      </div>
                      {inputImage === undefined ? (
                        ""
                      ) : (
                        <Image
                          src={`data:image/png;base64,${inputImage}`}
                          rounded
                          fluid
                          style={{ width: "220px", maxHeight: "140px" }}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>

              <Form.Control
                type="file"
                required={isNewImageSelected}
                accept="image/jpeg, image/png"
                name="image"
                onChange={handleInputImage}
              />
              <Form.Control.Feedback type="invalid">
                Please select a jpg/jpeg or png file
              </Form.Control.Feedback>

              <Modal.Footer className="mt-4">
                <Button
                  variant="secondary"
                  onClick={handleClose}
                  disabled={IsSaving}
                >
                  Close
                </Button>
                <Button type="submit" variant="success" disabled={IsSaving}>
                  save
                </Button>
              </Modal.Footer>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
